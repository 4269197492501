import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ReactNode } from 'react';

import './PackageModal.scss';

type PackageModalProps = {
  children: ReactNode;
  setIsDetailModalOpen: (val: boolean) => void;
  isDetailModalOpen: boolean;
  title: ReactNode;
};

const PackageModal = ({
  children,
  isDetailModalOpen,
  setIsDetailModalOpen,
  title,
}: PackageModalProps) => {
  return (
    <Modal
      title={title}
      centered
      open={isDetailModalOpen}
      className="package-modal-container"
      onCancel={() => setIsDetailModalOpen(false)}
      footer={[
        <Button
          size="large"
          type="ghost"
          onClick={() => setIsDetailModalOpen(false)}
        >
          Close
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};

export default PackageModal;
