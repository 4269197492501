type DotIconProps = {
  color: string;
  width: number;
  height: number;
};

const DotIcon = ({ color, width, height }: DotIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <circle cx="3" cy="3" r="3" fill={color} />
    </svg>
  );
};

export default DotIcon;
