import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Layout, Switch, Tooltip } from 'antd';
import arrayMove from 'array-move';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SCard, SDetailLayout } from 'app/components/StaycationUI';
import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { computeExperienceName } from 'app/components/commons/Experience/utils';
import BasicInfo from 'app/components/commons/Package/General/BasicInfo/BasicInfo';
import SortableExperienceList from 'app/components/commons/Package/General/ExperienceList/ExperienceList';
import SortableRoomList from 'app/components/commons/Package/General/RoomList/RoomList';
import Spinner from 'app/components/commons/Spinner';
import ToggleableInput from 'app/components/commons/ToggleableInput/ToggleableInput';
import CustomInput from 'app/components/fields/Input';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { tryFetchExperiences } from 'app/redux/actions/experiences';
import { tryFetchHotels } from 'app/redux/actions/hotels';
import { trySavePackage } from 'app/redux/actions/packages';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { selectExperienceCategories } from 'app/redux/selectors/conf';
import {
  getExperiencesLoading,
  getHotelExperiences,
} from 'app/redux/selectors/experiences';
import { getHotelsHotels, getHotelsLoading } from 'app/redux/selectors/hotels';
import {
  selectPackagesCopywriting,
  selectPackagesLoading,
  selectPackagesPackage,
} from 'app/redux/selectors/packages';
import { getHotelRooms, getRoomsLoading } from 'app/redux/selectors/rooms';
import { parseInputNumber } from 'app/utils/typing';

import './General.scss';

const { Content } = Layout;

export const General = ({ headerMenu, headerExtra, packageId }: Props) => {
  const pkgRaw = useAppSelector(selectPackagesPackage);
  const pkg = packageId === pkgRaw?.id ? pkgRaw : null;
  const pkgLoading = useAppSelector(selectPackagesLoading);
  const hotelExperiences = useAppSelector(getHotelExperiences);
  const expLoading = useAppSelector(getExperiencesLoading);
  const rooms = useAppSelector(getHotelRooms);
  const roomsLoading = useAppSelector(getRoomsLoading);
  const packageNameRaw = useAppSelector(selectPackagesCopywriting);
  const packageName = packageNameRaw?.name;
  const experienceCategories = useAppSelector(selectExperienceCategories);
  const hotels = useAppSelector(getHotelsHotels);
  const hotelsLoading = useAppSelector(getHotelsLoading);
  const dispatch = useAppDispatch();
  const submitPackage = useCallback(
    (values: typeof formattedValues) =>
      dispatch(trySavePackage({ pkg: values })),
    [dispatch]
  );
  const fetchExperiences = useCallback(
    (params: any) => dispatch(tryFetchExperiences(params)),
    [dispatch]
  );
  const fetchHotels = useCallback(() => dispatch(tryFetchHotels()), [dispatch]);

  const history = useHistory();
  const [mode, setMode] = useState<'view' | 'edit'>(
    packageId ? 'view' : 'edit'
  );
  const [isDirtyMove, setIsDirtyMove] = useState(false);
  const [form] = Form.useForm();
  const { currencySymbol } = useContext(CurrencyContext);

  const hotelId: number | undefined = form.getFieldValue('hotelId');

  const onCancel = useCallback(() => {
    if (packageId) {
      setMode('view');
    } else {
      history.push('/packages');
    }
  }, [packageId, history]);

  const hotel = useMemo(() => {
    if (!hotelId) {
      return null;
    }

    return hotels.find((h) => h.id === hotelId);
  }, [hotels, hotelId]);

  const formattedStaycationExperiences = useMemo(() => {
    if (!hotel) {
      return [];
    }

    const filtered = hotelExperiences.filter(
      (exp) => exp.countryId === hotel.countryId
    );

    const parsed = filtered.map((exp) => {
      const packageExperience = pkg?.experiences.find((e) => e.id === exp.id);

      return {
        ...packageExperience,
        ...exp,
        title: computeExperienceName({
          category: experienceCategories.find(
            (cat) => cat.id === exp.categoryId
          ),
          ...exp,
        }),
        description: undefined,
        avatar: exp.coverPicture,
        included: packageExperience?.included ? 'Included' : 'Add-on',
        pictures: exp.pictures,
        id: exp.id,
      };
    });

    return parsed;
  }, [hotelExperiences, hotel, pkg?.experiences, experienceCategories]);

  const formattedHotelExperiences = useMemo(() => {
    if (!hotel) {
      return [];
    }

    const filtered = hotelExperiences.filter((exp) => exp.hotelId === hotel.id);

    const parsed = filtered.map((exp) => {
      const packageExperience = pkg?.experiences.find((e) => e.id === exp.id);

      return {
        ...packageExperience,
        ...exp,
        title: computeExperienceName({
          category: experienceCategories.find(
            (cat) => cat.id === exp.categoryId
          ),
          ...exp,
        }),
        description: undefined,
        avatar: exp.coverPicture,
        included: packageExperience?.included ? 'Included' : 'Add-on',
        pictures: exp.pictures,
        id: exp.id,
      };
    });

    return parsed;
  }, [pkg, experienceCategories, hotelExperiences, hotel]);

  const formattedRooms = useMemo(
    () =>
      rooms.map((room) => ({
        ...room,
        title: room.name || room.categoryName,
        description: room.name ? room.categoryName : undefined,
        avatar: room.pictures[0],
        id: room.id,
      })),
    [rooms]
  );

  const formattedValues = useMemo(
    () =>
      packageId && pkg
        ? {
            ...pkg,
            experiences: pkg.experiences.map((exp) => {
              const expFull = formattedHotelExperiences
                .concat(formattedStaycationExperiences)
                ?.find((e) => e.id === exp.id);

              return { ...exp, ...expFull };
            }),
            rooms: pkg.rooms
              .map((room) => formattedRooms.find((r) => r.id === room))
              .filter((room) => !!room),
          }
        : {
            experiences: [],
            rooms: [],
            dayPackage: false,
            additionalAdultPrice: undefined,
            singleCustomerDiscount: undefined,
            childrenAllowed: true,
          },
    [
      packageId,
      pkg,
      formattedHotelExperiences,
      formattedStaycationExperiences,
      formattedRooms,
    ]
  );

  // We want to reset the form every time the formattedValues changes,
  // because it means one of the requests (getHotelExperiences, getHotelRooms... ) was resolved.
  // If we don't do it, the form will be filled with incomplete values.
  // To avoid this kind of tricks, we could start to render the form only after all the requests are resolved.
  useEffect(() => {
    if (packageId && formattedValues) {
      form.resetFields();
    }
  }, [packageId, formattedValues, form]);

  useEffect(() => {
    fetchHotels();
    fetchExperiences({
      isPublished: true,
      isStaycationExp: true,
    });
  }, [fetchHotels, fetchExperiences]);

  const checkIncluded = (values: any) =>
    !values.experiences.length ||
    values.experiences.some((exp: any) => exp.included === 'Included');

  const isLoading = hotelsLoading || pkgLoading || expLoading || roomsLoading;

  const renderPaxOptionInput = useCallback(
    (paxOption: 'additionalAdultPrice' | 'singleCustomerDiscount') => {
      const wordingFirstPart =
        paxOption === 'additionalAdultPrice'
          ? 'Apply an additional fee of'
          : 'Apply a discount of';
      const wordingSecondPart =
        paxOption === 'additionalAdultPrice'
          ? 'per extra adult guest'
          : 'for single guest';

      return (
        <div className="input-value">
          {mode === 'view' ? (
            <div>
              {form.getFieldValue([paxOption]) ?? formattedValues[paxOption]}
              {currencySymbol}
            </div>
          ) : (
            <>
              {wordingFirstPart}
              <Form.Item name={paxOption} noStyle>
                <CustomInput
                  suffix={currencySymbol}
                  onWheel={(e) => e.currentTarget.blur()}
                  min={0}
                  parser={parseInputNumber}
                />
              </Form.Item>
            </>
          )}
          {wordingSecondPart}
        </div>
      );
    },
    [mode, form, formattedValues, currencySymbol]
  );

  const paxAdultOptions = useMemo(
    () => [
      {
        title: 'Fee for extra adult',
        renderInput: renderPaxOptionInput('additionalAdultPrice'),
        value: formattedValues.additionalAdultPrice,
        name: 'additionalAdultPrice',
      },
      {
        title: 'Single guest discount',
        renderInput: renderPaxOptionInput('singleCustomerDiscount'),
        value: formattedValues.singleCustomerDiscount,
        name: 'singleCustomerDiscount',
      },
    ],
    [
      formattedValues.additionalAdultPrice,
      formattedValues.singleCustomerDiscount,
      renderPaxOptionInput,
    ]
  );

  const onFinish = useCallback(
    (values: any) => {
      const formattedPkg = {
        ...values,
        experiences: values.experiences.map((exp: any) => ({
          ...exp,
          discover: exp.discover || false,
          included: exp.included === 'Included',
        })),
        rooms: values.rooms.map((room: any) => room.id),
        id: packageId,
      };

      submitPackage(formattedPkg);
      setMode('view');
    },
    [packageId, submitPackage]
  );

  return (
    <FormLegacyRenderProp
      form={form}
      initialValues={formattedValues}
      onFinish={onFinish}
      className="general-form"
    >
      {(
        values,
        { resetFields, isFieldsTouched, submit, setFieldsValue, getFieldsError }
      ) => (
        <SDetailLayout
          title={
            packageId && pkg
              ? packageName && packageName.length
                ? packageName
                : `Package #${pkg.id}`
              : 'New Package'
          }
          mode={mode}
          onEdit={() => setMode('edit')}
          onSave={() => {
            submit();
            setIsDirtyMove(false);
          }}
          onCancel={() => {
            onCancel();
            setIsDirtyMove(false);
          }}
          reset={resetFields}
          isDirty={isDirtyMove || isFieldsTouched()}
          headerMenu={headerMenu}
          headerExtra={headerExtra}
          disabled={mode === 'edit' && !checkIncluded(values)}
          isValid={getFieldsError().every((item) => item.errors.length > 0)}
        >
          <Content className="package-detail__content">
            <Spinner spinning={isLoading}>
              <SCard title="Basic information">
                <BasicInfo mode={mode} values={values} newHotel={!packageId} />
              </SCard>
              {!!values.hotelId && !!hotelExperiences && !!rooms && (
                <>
                  <SCard title="Experiences">
                    <SortableExperienceList
                      mode={mode}
                      selectedExperiences={values?.experiences}
                      hotelExperiences={formattedHotelExperiences}
                      staycationExperiences={formattedStaycationExperiences}
                      move={({ oldIndex, newIndex }) => {
                        setIsDirtyMove(true);
                        setFieldsValue({
                          experiences: arrayMove(
                            values.experiences,
                            oldIndex,
                            newIndex
                          ),
                        });
                      }}
                    />
                  </SCard>
                  <SCard title="Rooms">
                    <SortableRoomList
                      mode={mode}
                      rooms={values?.rooms}
                      allRooms={formattedRooms}
                      move={({ oldIndex, newIndex }) => {
                        setIsDirtyMove(true);
                        setFieldsValue({
                          rooms: arrayMove(values.rooms, oldIndex, newIndex),
                        });
                      }}
                    />
                  </SCard>
                  <SCard
                    title="PAX options"
                    subtitle="This fee/discount will be applied to the total price (package price + room price)"
                  >
                    <div className="container">
                      <div className="adult-section">
                        <div className="section-title">
                          Adults
                          <Tooltip title={`Over 12 years old`}>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                        <div className="section-switch">
                          {paxAdultOptions.map((section, index) => (
                            <div key={index} className="sectionContainer">
                              <div>{section.title}</div>
                              <ToggleableInput
                                value={section.value}
                                disabled={mode === 'view'}
                                name={section.name}
                              >
                                {section.renderInput}
                              </ToggleableInput>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="chidren-section">
                        <div className="section-title">
                          Children
                          <Tooltip title={`Between 2 and 12 years old`}>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>

                        <div className="section-switch">
                          <div className="sectionContainer">
                            <div>Allow children</div>
                            <Form.Item
                              noStyle
                              name={'childrenAllowed'}
                              valuePropName={
                                pkg?.childrenAllowed ? 'checked' : 'unchecked'
                              }
                            >
                              <Switch disabled={mode === 'view'} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SCard>
                </>
              )}
            </Spinner>
          </Content>
        </SDetailLayout>
      )}
    </FormLegacyRenderProp>
  );
};

type Props = {
  headerMenu?: ReactNode;
  headerExtra: Array<ReactNode>;
  packageId: number | undefined;
};

const mapDispatchToProps = (dispatch: any) => ({
  submitPackage: (values: any) => dispatch(trySavePackage({ pkg: values })),
});

export default connect(undefined, mapDispatchToProps)(General);
