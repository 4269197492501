import { slugify } from 'app/utils/strings';

export const getFileSizeInBytes = (size: number): string => {
  const kB = size / 1024;
  const MB = kB / 1024;
  const GB = MB / 1024;

  if (kB < 1) {
    return `${size} B`;
  }

  if (MB < 1) {
    return `${Math.round(kB)} kB`;
  }

  if (GB < 1) {
    return `${Math.round(MB)} MB`;
  }

  return `${Math.round(GB)} GB`;
};

export const getFilename = (filename: string): string => {
  return slugify(filename.split('.')[0]);
};

export const downloadFile = (file: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};
