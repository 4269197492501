import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Formatter<T> = {
  [K in keyof T]?: (val: T[K]) => string | undefined;
};

type Extractor<T> = {
  [K in keyof T]?: (v: string) => T[K] | undefined;
};

type ParamKey<T> = {
  [K in keyof T]: string;
};

export const ARRAY_SEPARATOR = ',';

function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

export const parseArrayParam =
  <T,>(fn: (_: string) => T | undefined) =>
  (val: string) =>
    val.split(ARRAY_SEPARATOR).map(fn).filter(isDefined);

export const extractBoolean = (val: string) =>
  val === 'true' ? true : val === 'false' ? false : undefined;

export const useQueryParamHistory = <T extends { [k: string]: unknown }>(
  init: Partial<T>,
  paramKeys: ParamKey<T>,
  formatter?: Formatter<T>,
  extractor?: Extractor<T>
) => {
  const history = useHistory();
  const { search: searchParamsString } = useLocation();
  const [queryParam, setQueryParam] = useState(init);

  const addQueryParam = useCallback(
    <K extends keyof T>(
      previous: Partial<T>,
      key: K,
      value: T[K] | undefined
    ) => {
      if (value === undefined) {
        return previous;
      }

      const next = { ...previous, [key]: value };

      return next;
    },
    []
  );

  const removeQueryParam = <K extends keyof T>(
    previous: Partial<T>,
    key: K
  ) => {
    const { [key]: toRemove, ...next } = previous;

    // Omit<Partial<T>, K> should be a Partial<T>
    return next as Partial<T>;
  };

  useEffect(() => {
    setQueryParam((oldNext) => {
      const searchParams = new URLSearchParams(searchParamsString);
      const next = Array.from(searchParams.keys()).reduce((prev, curr) => {
        const param = searchParams.get(curr);

        const keys = Object.entries(paramKeys).filter((kv) => kv[1] === curr);

        if (keys.length === 0) {
          return prev;
        }

        const key = keys[0][0];

        if (extractor?.hasOwnProperty(key) && param) {
          return addQueryParam(prev, key, extractor[key]?.(param));
        }

        return addQueryParam(
          prev,
          key,
          // Can't be undefined since we're looping on searchParams keys
          param as T[string]
        );
      }, oldNext);

      return next;
    });
  }, [setQueryParam, addQueryParam, searchParamsString, extractor, paramKeys]);

  const appendParam = <K extends keyof T>(
    key: K,
    value: T[K] | undefined,
    searchParams: URLSearchParams
  ): void => {
    if (value === undefined) {
      return;
    }

    if (formatter?.hasOwnProperty(key)) {
      const fn = formatter[key];
      const formatted = fn?.(value);

      if (formatted) {
        if (Array.isArray(formatted)) {
          formatted.join(ARRAY_SEPARATOR);
        } else {
          searchParams.append(paramKeys[key], formatted);
        }
      }

      return;
    }

    if (
      typeof value === 'number' ||
      typeof value === 'string' ||
      typeof value === 'boolean'
    ) {
      searchParams.append(paramKeys[key], value.toString());

      return;
    }

    if (typeof value === 'boolean') {
      searchParams.append(paramKeys[key], value.toString());

      return;
    }

    if (Array.isArray(value)) {
      searchParams.append(paramKeys[key], value.join(ARRAY_SEPARATOR));
    }

    return;
  };

  const updateHistory = (next: Partial<T>) => {
    const nextQueryParams = new URLSearchParams();

    Object.keys(next).forEach((k) => appendParam(k, next[k], nextQueryParams));
    history.replace({ search: nextQueryParams.toString() });
  };

  const updateQueryParam = (values: Partial<T>) => {
    const nextParams = Object.keys(values).reduce((prev, curr) => {
      if (values[curr] === undefined) {
        return removeQueryParam(prev, curr);
      }

      return addQueryParam(prev, curr, values[curr]);
    }, queryParam);

    updateHistory(nextParams);
    setQueryParam(nextParams);
  };

  return { queryParam, updateQueryParam };
};

export function useCreateQueryParams(): Record<string, string | number> {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    const queryObject: Record<string, string | number> = {};

    params.forEach((value, key) => {
      const numericValue = Number(value);

      if (!isNaN(numericValue)) {
        queryObject[key] = numericValue;
      } else {
        queryObject[key] = value;
      }
    });

    return queryObject;
  }, [search]);
}
