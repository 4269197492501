import { ReactNode } from 'react';

import './EmptyState.scss';

type EmptyStateProps = { title: string; description: string; icon: ReactNode };

export const EmptyState = ({ title, description, icon }: EmptyStateProps) => {
  return (
    <div className="empty-state-container">
      {icon}
      <div className="empty-state-texts">
        <div className="empty-state-title">{title}</div>
        <div className="empty-state-description">{description}</div>
      </div>
    </div>
  );
};

export default EmptyState;
