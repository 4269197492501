import { useHistory, useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const setSearchParams = (params: { [key in string]: string }) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const getSearchParam = (param: string) => {
    return searchParams.get(param);
  };

  const clearSearchParams = () => {
    Array.from(searchParams.keys()).forEach((key) => {
      searchParams.delete(key);
    });

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return {
    setSearchParams,
    getSearchParam,
    clearSearchParams,
  };
};
