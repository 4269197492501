import React from 'react';

export const AdminLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 70 24"
    width="70px"
    height="24px"
  >
    <g fill="#fff" clip-path="url(#a)">
      <path
        fill-rule="evenodd"
        d="M4 0a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm8.281 18.544c0 .643.522 1.176 1.154 1.176.632 0 1.143-.522 1.143-1.176 0-.655-.51-1.176-1.143-1.176-.632 0-1.154.532-1.154 1.176ZM7.7 19.52c-.01 1.131-.82 1.875-2.551 1.875-1.62 0-2.507-.754-2.607-1.863H4.36c.078.377.344.565.832.565.422 0 .666-.166.666-.388 0-.177-.167-.277-.41-.322l-.966-.189c-1.043-.2-1.775-.71-1.775-1.697 0-1.043.91-1.82 2.43-1.82 1.031 0 1.82.378 2.196.977.034.055.111.044.111-.022v-.866h.577v-1.02l1.798-.367v1.388h.61v1.675h-.61v1.741c0 .289.155.455.421.455h.189v1.675h-.777c-1.054 0-1.63-.577-1.63-1.586v-2.285h-2.23c-.012-.267-.267-.455-.678-.455-.41 0-.632.133-.632.343 0 .167.078.244.344.311l1.609.377c.754.178 1.276.6 1.264 1.498Zm9.586 2.851Zm1.963 0h-1.963l.555-1.453c.022-.044.01-.11-.012-.155l-1.375-3.64c-.034-.1-.167-.077-.167.023v4.171h-1.73v-.333c0-.088-.056-.122-.134-.055-.322.289-.832.477-1.331.477-1.442 0-2.607-1.242-2.607-2.862s1.176-2.863 2.651-2.863c.522 0 .988.156 1.287.444.078.078.133.056.133-.044v-.31h3.362l.854 2.451c.034.089.122.089.156 0l.854-2.452h1.964l-2.497 6.601Z"
        clip-rule="evenodd"
      />
      <path d="m35.76 16-.408-1.5h-2.496l-.408 1.5h-2.352l2.436-8.4h3.252L38.22 16h-2.46Zm-1.728-5.82-.696 2.544h1.536l-.696-2.544h-.144ZM46.48 11.8c0 2.88-1.656 4.2-4.296 4.2h-3.36V7.6h3.36c2.436 0 4.296 1.32 4.296 4.2Zm-2.556 0c0-1.8-.684-2.316-1.752-2.316h-.888v4.62h.888c1.068 0 1.752-.504 1.752-2.304Zm7.263 4.2-1.596-5.364h-.084V16h-2.184V7.6h3.648l1.032 2.976h.084L53.215 7.6h3.36V16h-2.184v-5.364h-.084L52.723 16h-1.536Zm6.71 0V7.6h2.46V16h-2.46Zm11.132 0h-3.228l-1.848-5.304h-.084V16h-2.196V7.6h3.228l1.848 5.136h.084V7.6h2.196V16Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h70v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
