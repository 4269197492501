import { Button, Modal } from 'antd';

import './ForceNewOrder.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  submitNewOrder: () => void;
};

const ForceNewOrder = ({ isOpen, onClose, submitNewOrder }: Props) => {
  const onSubmit = () => {
    submitNewOrder();

    onClose();
  };

  return (
    <Modal
      title="Force new order"
      className="force-new-order-modal"
      open={isOpen}
      onCancel={onClose}
      centered
      footer={[
        <Button type="ghost" className="close-button" onClick={onClose}>
          Close
        </Button>,
        <Button
          key="save"
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
          className="saveBtn"
        >
          Force new order
        </Button>,
      ]}
      destroyOnClose
    >
      <div>
        By forcing the creation of a new order, you understand that it will
        <span className="bold"> override all order marked as “Draft”</span> and
        that you are initiating the sending of welcome kits to the hotels.
      </div>
    </Modal>
  );
};

export default ForceNewOrder;
