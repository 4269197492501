import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { apiRequest } from 'app/utils/request/api';

export type WelcomeKitHotel = {
  id: number;
  name: string;
  pauseWelcomeKit: boolean;
  countryId: number;
  currentStock: number;
  maxQuantity?: number;
};

export const deliverableHotelsPath =
  '/gateway/hotel-logistics/welcome-kit/hotels/:orderId/deliverable' as const;

export function useDeliverableHotels(orderId: number) {
  return useQuery({
    queryKey: [deliverableHotelsPath],
    queryFn: () =>
      apiRequest({
        path: deliverableHotelsPath,
        params: { orderId: orderId.toString() },
      }) as Promise<WelcomeKitHotel[]>,
    onError: () => {
      message.error('Error fetching Deliverable Hotels');
    },
  });
}
