import { SHeader } from 'app/components/StaycationUI';

import { ButtonAutoInventory } from '../../ExtranetInventory/AutoInventory/ButtonAutoInventory/ButtonAutoInventory';
import WebsiteButton from '../../ExtranetInventory/WebsiteButton/WebsiteButton';
import PackageButton from '../../ExtranetPackages/_components/PackageButton/PackageButton';
import { TaskCenterButton } from '../TaskCenter/TaskCenterButton/TaskCenterButton';

type ExtranetHeaderProps = {
  page: string;
  subTitle?: any;
  withWebsiteButton?: boolean;
  withAutoInventoryButton?: boolean;
  hasAutoInventoryActivated?: boolean;
  setIsAutoInventoryModalOpen?: (value: boolean) => void;
  withPackageButton?: boolean;
  packageSlug?: string;
};

export const ExtranetHeader = ({
  page,
  subTitle,
  withWebsiteButton = false,
  withAutoInventoryButton = false,
  hasAutoInventoryActivated = false,
  setIsAutoInventoryModalOpen,
  withPackageButton = false,
  packageSlug = '',
}: ExtranetHeaderProps) => {
  return (
    <>
      <SHeader
        title={page}
        subtitle={subTitle}
        extra={[
          withPackageButton && (
            <PackageButton key={'package-button'} packageSlug={packageSlug} />
          ),
          withWebsiteButton && <WebsiteButton key={'website-button'} />,
          withAutoInventoryButton && setIsAutoInventoryModalOpen && (
            <ButtonAutoInventory
              key={'auto-inventory-button'}
              setIsAutoInventoryModalOpen={setIsAutoInventoryModalOpen}
              hasAutoInventoryActivated={hasAutoInventoryActivated}
            />
          ),
          <TaskCenterButton key={'task-center-button'} />,
        ]}
      />
    </>
  );
};
