const BedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_40_5776)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 0.833496C17.9976 0.833496 19.0864 1.8747 19.1624 3.18665L19.1667 3.3335V8.97041C19.644 9.39775 19.9563 10.0057 19.9958 10.6866L20 10.8335V18.3335C20 18.7937 19.6269 19.1668 19.1667 19.1668C18.7393 19.1668 18.3871 18.8451 18.3389 18.4307L18.3333 18.3335L18.3331 16.6668H1.66642L1.66667 18.3335C1.66667 18.7609 1.34497 19.1131 0.930518 19.1612L0.833333 19.1668C0.40597 19.1668 0.053744 18.8451 0.00560644 18.4307L0 18.3335V10.8335C0 10.0934 0.321932 9.42824 0.833333 8.97041V3.3335C0.833333 2.00255 1.87454 0.913811 3.18649 0.837742L3.33333 0.833496H16.6667ZM1.97999 10.1826C2.0551 10.1521 2.12476 10.111 2.18709 10.061C2.28374 10.0218 2.38937 10.0002 2.5 10.0002H17.5L17.5971 10.0058C17.6726 10.0146 17.745 10.0334 17.8129 10.061C17.8752 10.111 17.9449 10.1521 18.02 10.1826C18.2109 10.3354 18.3333 10.5703 18.3333 10.8335V15.0002H1.66667V10.8335L1.67228 10.7364C1.69828 10.513 1.8129 10.3163 1.97999 10.1826ZM17.5 8.3335V7.50016C17.5 7.07324 17.1779 6.72064 16.7638 6.67244L16.6667 6.66683H11.6667C11.2397 6.66683 10.8871 6.98894 10.8389 7.40307L10.8333 7.50016V8.3335H17.5ZM9.16667 8.3335V7.50016C9.16667 7.07324 8.84456 6.72064 8.43043 6.67244L8.33333 6.66683H3.33333C2.90641 6.66683 2.55381 6.98894 2.50561 7.40307L2.5 7.50016V8.3335H9.16667ZM10 5.63708C10.4083 5.27154 10.9374 5.03818 11.5198 5.00441L11.6667 5.00016H16.6667C16.9588 5.00016 17.2393 5.05034 17.5 5.14253V3.3335C17.5 2.90657 17.1779 2.55397 16.7638 2.50578L16.6667 2.50016H3.33333C2.90641 2.50016 2.55381 2.82227 2.50561 3.2364L2.5 3.3335V5.14253C2.71669 5.06589 2.94708 5.01829 3.18649 5.00441L3.33333 5.00016H8.33333C8.9735 5.00016 9.55763 5.24105 10 5.63708Z"
        fill="#808080"
      />
    </g>
    <defs>
      <clipPath id="clip0_40_5776">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BedIcon;
