import { Picture } from 'app/typings/Picture/Picture';

const emojiDbPrefix = 'illustration_';

function formatEmojiName(emojiName: string) {
  return emojiName.replace(emojiDbPrefix, '').replaceAll('_', ' ');
}

export function formatEmojiList(emojis: Picture[]) {
  return emojis
    .map((emoji) => ({ ...emoji, name: formatEmojiName(emoji.name) }))
    .sort((emoji1, emoji2) => emoji1.name.localeCompare(emoji2.name));
}
