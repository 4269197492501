import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { XLSX_DATE_FORMAT } from 'app/utils/dates';
import { downloadFile } from 'app/utils/files';
import { apiRequest } from 'app/utils/request/api';

export const pathDownloadOrders =
  '/gateway/hotel-logistics/welcome-kit/orders/:orderId/download' as const;

export function useDownloadOrder() {
  const queryClient = useQueryClient();

  const downloadOrder = async (orderId: number, sentAt: string) => {
    const blob = await queryClient.fetchQuery<Blob, unknown>({
      queryKey: [pathDownloadOrders, { orderId }],
      queryFn: () =>
        apiRequest({
          path: pathDownloadOrders,
          params: { orderId: orderId.toString() },
        }),
      staleTime: 5 * MILLISECONDS_IN_MINUTE,
    });

    const fileName = `${dayjs(sentAt).format(
      XLSX_DATE_FORMAT
    )} - Livraison Staycation.xlsx`;

    downloadFile(blob, fileName);
  };

  return {
    downloadOrder,
    isLoading: !!queryClient.isFetching(),
  };
}
