import { ReactNode } from 'react';

type PackageDetailRoomProps = {
  icon: ReactNode;
  title: string;
  description: string;
};

const PackageDetailRoom = ({
  icon,
  title,
  description,
}: PackageDetailRoomProps) => {
  return (
    <div className="package-detail-room">
      <div className="room-icon">{icon}</div>
      <div className="room-texts">
        <div className="room-title">{title}</div>
        <div className="room-description">{description}</div>
      </div>
    </div>
  );
};

export default PackageDetailRoom;
