import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Layout, PageHeader, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { useCreateNewOrder } from 'app/hooks/data/welcomeKit/orders/useOrder';
import { useSearchParams } from 'app/utils/searchParams';

import WelcomeKitOrders from './Orders/WelcomeKitOrders';
import WelcomeKitStocks from './Stocks/WelcomeKitStocks';
import './WelcomeKit.scss';
import { getWelcomeKitListCategory } from './utils';

const WelcomeKit = () => {
  const [searchValue, setSearchValue] = useState('');
  const { mutate: createNewOrder, isLoading } = useCreateNewOrder();
  const { getSearchParam, setSearchParams, clearSearchParams } =
    useSearchParams();
  const initialCategoryParams = getSearchParam('category');
  const initialCategory = getWelcomeKitListCategory(initialCategoryParams);
  const [activeTab, setActiveTab] = useState<string>(initialCategory);

  const [forceNewOrderModalOpen, setForceNewOrderModalOpen] =
    useState<boolean>(false);

  const onChangeTabs = (key: string) => {
    setActiveTab(key);
    clearSearchParams();
    setSearchParams({ category: key });
  };

  const items = useMemo(() => {
    return [
      {
        label: 'Stocks',
        key: 'stocks',
      },
      {
        label: 'Orders',
        key: 'orders',
      },
    ];
  }, []);

  return (
    <Layout className="page-list">
      <PageHeader className="header" title="Welcome kit" />
      <Content className={classNames('body', 'welcome-kit-container')}>
        <div className="welcome-kit-tabs">
          <Tabs
            defaultActiveKey={initialCategory}
            items={items}
            onChange={onChangeTabs}
            tabBarExtraContent={
              activeTab === 'stocks' ? (
                <Input
                  placeholder="Search"
                  value={searchValue}
                  allowClear
                  onChange={(event) => setSearchValue(event.target.value)}
                  suffix={<SearchOutlined />}
                  className="search"
                />
              ) : (
                <Button
                  onClick={() => setForceNewOrderModalOpen(true)}
                  loading={isLoading}
                >
                  Force new order
                </Button>
              )
            }
          />
        </div>
        <div className="welcome-kit-tables">
          {activeTab === 'stocks' ? (
            <WelcomeKitStocks searchValue={searchValue} />
          ) : (
            <WelcomeKitOrders
              forceNewOrderModalOpen={forceNewOrderModalOpen}
              setForceNewOrderModalOpen={setForceNewOrderModalOpen}
              submitNewOrder={createNewOrder}
            />
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default WelcomeKit;
