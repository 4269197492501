import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';

import { useUpdateDeliveryQuantity } from 'app/hooks/data/welcomeKit/deliveries/useDeliveries';

import './UpdateDeliveryQuantityModal.scss';

type Props = {
  deliveryId: number;
  currentDeliveryQuantity: number;
  hotel: { name: string; currentStock: number };
  isOpen: boolean;
  closeModal: () => void;
  maxQuantity?: number;
};

export const QuantityDeliveryModal = ({
  deliveryId,
  currentDeliveryQuantity,
  hotel,
  isOpen,
  closeModal,
  maxQuantity,
}: Props) => {
  const { mutate: updateDeliveryQuantity } =
    useUpdateDeliveryQuantity(deliveryId);
  const [currentQuantity, setCurrentQuantity] = useState<number>(
    currentDeliveryQuantity
  );

  useEffect(() => {
    setCurrentQuantity(currentDeliveryQuantity);
  }, [currentDeliveryQuantity]);

  const onConfirm = () => {
    if (currentQuantity !== 0) {
      updateDeliveryQuantity(currentQuantity);
    }

    setCurrentQuantity(currentDeliveryQuantity);
    closeModal();
  };

  const onCancel = () => {
    setCurrentQuantity(currentDeliveryQuantity);
    closeModal();
  };

  const renderFooter = () => {
    return (
      <>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          key="save"
          type="primary"
          disabled={!currentQuantity}
          onClick={() => onConfirm()}
        >
          Save
        </Button>
      </>
    );
  };

  const renderStockEstimate = () => {
    return (
      <div className="quantity-segment">
        <div>Updated hotel stock after delivery:</div>
        <span className="quantity-span">
          {hotel.currentStock || 0}
          {!!currentQuantity && currentQuantity !== 0 && (
            <span className="added-quantity">
              {` + ${currentQuantity} = ${
                hotel.currentStock + currentQuantity
              }`}
            </span>
          )}
        </span>
      </div>
    );
  };

  const renderTitle = () => (
    <div>
      <div>Edit welcome kit delivery</div>
      <div className="subtitle">{hotel.name}</div>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      title={renderTitle()}
      onCancel={onCancel}
      footer={renderFooter()}
      width={600}
    >
      <div className="input-container">
        <label>How many do you want to send?</label>
        <Input
          placeholder="Enter a quantity"
          type="number"
          min={1}
          value={currentQuantity}
          onChange={(event) => {
            setCurrentQuantity(parseInt(event.target.value, 10));
          }}
        />
      </div>
      {maxQuantity &&
        currentQuantity &&
        currentQuantity + hotel.currentStock > maxQuantity && (
          <Alert
            className="hotel-alert"
            type="info"
            message="Limited space"
            description={`This hotel cannot store more than ${maxQuantity} welcome kit${
              maxQuantity === 1 ? '' : 's'
            }.`}
            icon={<InfoCircleOutlined size={16} />}
            showIcon
          />
        )}
      {renderStockEstimate()}
    </Modal>
  );
};
