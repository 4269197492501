import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { AlignType } from 'rc-table/lib/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import TagWithIcon from 'app/components/commons/TagWithIcon/TagWithIcon';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import { useResumeStocks } from 'app/hooks/data/welcomeKit/stocks/useStocks';
import { useGetStocksList } from 'app/hooks/data/welcomeKit/useGetWelcomeKitLists';
import {
  STATUS_TYPE,
  StatusType,
  StockListItem,
} from 'app/typings/WelcomeKit/welcomeKit';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import { scrollToTop } from 'app/utils/scroll';
import { SortOrder, formatSortingForAPI } from 'app/utils/sort';
import {
  parseStringToArray,
  parseUppercaseTextToCapitalize,
} from 'app/utils/string';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../utils';

import EditStockHotelModal from './Modals/EditStockHotel';
import PausedDeliveryModal from './Modals/PausedDeliveryModal';

const ALIGN_CENTER = 'center' as AlignType;

type QueryParamsType = {
  pageNumber: number;
  stockSorting: SortOrder;
  statusFilter?: StatusType[];
  search?: string;
};

const initialQueryParam: QueryParamsType = {
  pageNumber: DEFAULT_PAGE,
  stockSorting: 'none',
  statusFilter: [STATUS_TYPE.DELIVERABLE, STATUS_TYPE.PAUSED],
  search: undefined,
};

type StocksProps = {
  searchValue: string;
};

export type ActionType = 'editStock' | 'editMaxStock';

const WelcomeKitStocks = ({ searchValue }: StocksProps) => {
  const location = useLocation();
  const history = useHistory();

  const [selectedHotel, setSelectedHotel] = useState<StockListItem>();
  const [actionType, setActionType] = useState<ActionType>();
  const [openPauseModal, setOpenPauseModal] = useState<boolean>(false);
  const { mutate: resumeStocks } = useResumeStocks();

  const queryParams = useMemo(() => {
    const query = new URLSearchParams(location.search);

    return {
      search: query.get('search') || initialQueryParam.search,
      pageNumber: parseInt(
        query.get('pageNumber') || initialQueryParam.pageNumber.toString(),
        10
      ),
      stockSorting:
        (query.get('stockSorting') as SortOrder) ??
        initialQueryParam.stockSorting,
      statusFilter: (() => {
        const filter = query.get('statusFilter');

        if (filter === null) {
          return initialQueryParam.statusFilter;
        }

        if (filter === '') {
          return [];
        }

        return parseStringToArray(filter) as StatusType[];
      })(),
    };
  }, [location.search]);

  const createPayload = useCallback(
    () => ({
      search: queryParams.search,
      sortOrder:
        queryParams.stockSorting === 'none'
          ? undefined
          : queryParams.stockSorting,
      status: queryParams.statusFilter ?? initialQueryParam.statusFilter,
      pageNumber: queryParams.pageNumber,
    }),
    [queryParams]
  );

  const { data: listHotelStocks, isLoading: listIsLoading } = useGetStocksList(
    createPayload()
  );

  const pagination = listHotelStocks?.meta;
  const updateQueryParams = useCallback(
    (params: Record<string, string | undefined>) => {
      const newParams = new URLSearchParams(location.search);

      Object.entries(params).forEach(([key, value]) => {
        if (value) newParams.set(key, value);
        else if (key === 'statusFilter') {
          newParams.set(key, '');
        } else {
          newParams.delete(key);
        }
      });

      history.push(`${location.pathname}?${newParams.toString()}`);
    },
    [history, location.pathname, location.search]
  );

  const debouncedSearch = useDebounce(searchValue);

  useEffect(() => {
    updateQueryParams({ search: debouncedSearch });
  }, [debouncedSearch, updateQueryParams]);

  const handleOnChange = (
    paginationConfig: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<StockListItem> | SorterResult<StockListItem>[]
  ) => {
    const { field, order } = Array.isArray(sorter) ? sorter[0] : sorter || {};

    const currentPage = paginationConfig.current ?? DEFAULT_PAGE;

    if (currentPage !== pagination?.pageNumber) {
      handlePagination(currentPage);
    } else {
      updateQueryParams({
        stockSorting:
          field === 'quantity'
            ? formatSortingForAPI(order ?? 'none')
            : initialQueryParam.stockSorting,
        pageNumber: `${currentPage}`,
      });
    }
  };

  const handleStatusFilter = (value: string[]) => {
    updateQueryParams({ statusFilter: value.join(',') ?? undefined });
  };

  const handlePagination = async (pageNumber: number) => {
    scrollToTop();
    updateQueryParams({ pageNumber: pageNumber.toString() });
  };

  const statusColumnTitle = (
    <ModalCheckBox
      title="Status"
      defaultValue={[STATUS_TYPE.DELIVERABLE, STATUS_TYPE.PAUSED]}
      onChange={handleStatusFilter}
      items={[
        {
          value: STATUS_TYPE.DELIVERABLE,
          label: parseUppercaseTextToCapitalize(STATUS_TYPE.DELIVERABLE),
        },
        {
          value: STATUS_TYPE.PAUSED,
          label: parseUppercaseTextToCapitalize(STATUS_TYPE.PAUSED),
        },
      ]}
      value={queryParams.statusFilter}
      isCenter={false}
    />
  );

  const renderStock = (stock: number) => stock ?? '-';

  const actionMenu = (hotelStock: StockListItem) => {
    const selectAction = (type: ActionType) => {
      setSelectedHotel(hotelStock);
      setActionType(type);
    };

    const handlePauseAction = () => {
      setOpenPauseModal(true);
      setSelectedHotel(hotelStock);
    };

    return (
      <div className="actions-menu">
        <Dropdown
          dropdownRender={() => (
            <Menu>
              <Menu.Item key="edit" onClick={() => selectAction('editStock')}>
                Edit stock
              </Menu.Item>
              <Menu.Item
                key="edit_maximum_stock"
                onClick={() => selectAction('editMaxStock')}
              >
                Edit maximum stock
              </Menu.Item>
              {!hotelStock.isPaused ? (
                <Menu.Item key="pause" onClick={handlePauseAction}>
                  Pause deliverability
                </Menu.Item>
              ) : (
                <Menu.Item
                  key="active"
                  onClick={() => resumeStocks(hotelStock.hotelId)}
                >
                  Resume deliverability
                </Menu.Item>
              )}
            </Menu>
          )}
          trigger={['click']}
        >
          <EllipsisOutlined rotate={90} />
        </Dropdown>
      </div>
    );
  };

  const columns = [
    { title: 'Hotel id', dataIndex: 'hotelId', width: '100px' },
    { title: 'Hotel name', dataIndex: 'name', width: '400px' },
    {
      title: 'Stock',
      dataIndex: 'quantity',
      sorter: true,
      render: renderStock,
    },
    {
      title: 'Maximum stock',
      dataIndex: 'maxQuantity',
      render: renderStock,
    },
    { title: 'Pending delivery', dataIndex: 'pendingDeliveryQuantity' },
    {
      title: statusColumnTitle,
      dataIndex: 'isPaused',
      width: '140px',
      filter: true,
      render: (isPaused: boolean) => (
        <TagWithIcon
          text={parseUppercaseTextToCapitalize(
            isPaused ? STATUS_TYPE.PAUSED : STATUS_TYPE.DELIVERABLE
          )}
          type={isPaused ? 'default' : 'success'}
        />
      ),
      className: 'stock-status',
    },
    {
      dataIndex: 'action',
      render: (_: undefined, value: StockListItem) => actionMenu(value),
      onCell: () => ({
        onClick: (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation(),
      }),
      align: ALIGN_CENTER,
      width: '45px',
    },
  ];

  return (
    <>
      <Table
        dataSource={listHotelStocks?.data}
        columns={columns}
        onChange={handleOnChange}
        sortDirections={['ascend', 'descend']}
        loading={listIsLoading}
        rowKey={'id'}
        pagination={{
          simple: true,
          showSizeChanger: false,
          pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
          current: pagination?.pageNumber ?? DEFAULT_PAGE,
          total: pagination?.itemCount || 1,
          position: ['topRight', 'bottomRight'],
        }}
      />
      {selectedHotel && openPauseModal && (
        <PausedDeliveryModal
          hotelStock={selectedHotel}
          isOpen
          onClose={() => {
            setSelectedHotel(undefined);
            setOpenPauseModal(false);
          }}
        />
      )}

      {selectedHotel && actionType && (
        <EditStockHotelModal
          hotelStock={selectedHotel}
          isMaxmumStockEdit={actionType === 'editMaxStock'}
          isOpen
          onClose={() => {
            setSelectedHotel(undefined);
            setActionType(undefined);
          }}
        />
      )}
    </>
  );
};

export default WelcomeKitStocks;
