import {
  FireOutlined,
  FundOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import { ReactNode, useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Pic from 'app/components/commons/Pic';
import { AdminLogo } from 'app/components/commons/icons/AdminLogo';
import { UserContext } from 'app/context/UserContext/UserContext';

import 'styles/layout/side-menu.scss';

const { SubMenu } = Menu;
const { Sider } = Layout;

type MenuItem = {
  title: string;
  link: string;
};

type SubMenuItems = Record<string, MenuItem>;

type MenuSection = {
  title: string;
  icon: ReactNode;
  items: SubMenuItems;
};

type MenuItems = Record<string, MenuSection>;

type MenuKeys =
  | 'catalog'
  | 'ecommerce'
  | 'purchase'
  | 'merchandising'
  | 'community';

const menuItemsOrder: MenuKeys[] = [
  'catalog',
  'ecommerce',
  'purchase',
  'merchandising',
  'community',
];

const subMenuItemsOrder: Record<MenuKeys, string[]> = {
  catalog: [
    'packages',
    'hotels',
    'experiences',
    'rooms',
    'galleries',
    'customTags',
  ],
  ecommerce: ['bills', 'sales', 'commercialGestures', 'welcomeKit'],
  purchase: ['bookings', 'giftCards'],
  merchandising: ['event', 'quickFilter', 'slides', 'guides', 'collections'],
  community: ['users', 'vouchers'],
};

const SideMenuAdmin = () => {
  const { user } = useContext(UserContext);
  const menuItems: MenuItems = {
    catalog: {
      title: 'Catalogue',
      icon: <ShopOutlined />,
      items: {
        packages: {
          title: 'Packages',
          link: '/packages',
        },
        hotels: {
          title: 'Hotels',
          link: '/hotels',
        },
        experiences: {
          title: 'Experiences',
          link: '/experiences',
        },
        rooms: {
          title: 'Rooms',
          link: '/rooms',
        },
        galleries: {
          title: 'Gallery',
          link: '/hotels/galleries',
        },
        customTags: {
          title: 'Custom tags',
          link: '/custom-tags',
        },
      },
    },
    ecommerce: {
      title: 'E-commerce',
      icon: <FundOutlined />,
      items: {
        bills: {
          title: 'Bills',
          link: '/bills',
        },
        welcomeKit: {
          title: 'Welcome kit',
          link: '/welcome-kit',
        },
      },
    },
    purchase: {
      title: 'Purchases',
      icon: <ShoppingOutlined />,
      items: {
        bookings: {
          title: 'Bookings',
          link: '/bookings',
        },
        giftCards: {
          title: 'Gift cards',
          link: '/gift-cards',
        },
      },
    },
    merchandising: {
      title: 'Merchandising',
      icon: <FireOutlined />,
      items: {
        event: {
          title: 'Events',
          link: '/edito-events',
        },
        slides: {
          title: 'Slider',
          link: '/slider',
        },
        guides: {
          title: 'Guides',
          link: '/guides',
        },
        collections: {
          title: 'Collections',
          link: '/collections',
        },
      },
    },
    community: {
      title: 'Community',
      icon: <TeamOutlined />,
      items: {
        users: {
          title: 'Users',
          link: '/users',
        },
        vouchers: {
          title: 'Vouchers',
          link: '/vouchers/new',
        },
      },
    },
  };

  if (user?.role === 'superadmin') {
    menuItems.merchandising.items = {
      quickFilter: {
        title: 'Quick Filters',
        link: '/quick-filters',
      },
      ...menuItems.merchandising.items,
    };

    menuItems.ecommerce.items = {
      ...menuItems.ecommerce.items,
      sales: {
        title: 'Sale times',
        link: '/sales',
      },
      commercialGestures: {
        title: 'Commercial Gestures',
        link: '/commercial-gestures',
      },
    };
  }

  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const rootKeys = [
    'catalog',
    'ecommerce',
    'purchase',
    'merchandising',
    'community',
  ];

  // Open only one submenu at a time
  const onOpenChange = (items: string[]) => {
    const latestOpenKey = items.find((key) => openKeys.includes(key));

    if (!rootKeys.includes(latestOpenKey ?? '')) {
      setOpenKeys(items);
    } else {
      setOpenKeys(
        latestOpenKey ? items.filter((key) => key !== latestOpenKey) : []
      );
    }
  };

  const renderItem = (key: string, { link, title }: MenuItem) => (
    <Menu.Item className="menu-item" key={key}>
      <NavLink to={link}>{title}</NavLink>
    </Menu.Item>
  );
  const renderSubMenu = (
    key: keyof typeof subMenuItemsOrder,
    {
      title,
      icon,
      items,
    }: { title: string; icon: ReactNode; items: Record<string, MenuItem> }
  ) => (
    <SubMenu key={key} title={title} icon={icon} className="submenu">
      {subMenuItemsOrder[key]
        .filter((subMenuKey) => !!items[subMenuKey])
        .map((subMenuKey) => renderItem(subMenuKey, items[subMenuKey]))}
    </SubMenu>
  );

  return (
    <Sider className="sider">
      <div>
        <Link to="/">
          <div className="title">
            <AdminLogo />
          </div>
        </Link>
        <Menu
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          theme="dark"
          className="menu"
          mode="inline"
        >
          {menuItemsOrder.map((key) =>
            renderSubMenu(key, menuItems[key as keyof MenuItems])
          )}
        </Menu>
      </div>
      <div className="bottom_admin">
        <Pic
          className="avatar"
          style={{ borderRadius: '50%' }}
          pictureId={user?.avatarId || 'static/default-avatar.png'}
        />
        {user?.firstName || `#${user?.id}`}
      </div>
    </Sider>
  );
};

SideMenuAdmin.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    role: PropTypes.string.isRequired,
    avatarId: PropTypes.string,
    id: PropTypes.number.isRequired,
  }),
};

export default SideMenuAdmin;
