import { Button, Form, Modal } from 'antd';
import { useContext } from 'react';

import CustomInput from 'app/components/fields/Input';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { UserContext } from 'app/context/UserContext/UserContext';
import {
  useEditHotelStocks,
  useEditMaxStocks,
} from 'app/hooks/data/welcomeKit/stocks/useStocks';
import { StockListItem } from 'app/typings/WelcomeKit/welcomeKit';
import { parseInputNumber } from 'app/utils/typing';

type FormValues = {
  quantity?: number;
  maxQuantity?: number;
};

type Props = {
  hotelStock: StockListItem;
  isMaxmumStockEdit: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const EditStockHotelModal = ({
  hotelStock,
  isOpen,
  isMaxmumStockEdit,
  onClose,
}: Props) => {
  const { user } = useContext(UserContext);

  const [form] = Form.useForm();

  const { mutate: editHotelStocks } = useEditHotelStocks(hotelStock.hotelId);
  const { mutate: editHotelMaxStocks } = useEditMaxStocks(hotelStock.hotelId);

  const onFinish = (values: FormValues) => {
    if (isMaxmumStockEdit) {
      editHotelMaxStocks({ maxQuantity: values.maxQuantity });
    }

    if (!isMaxmumStockEdit && values.quantity !== undefined && user) {
      editHotelStocks({ quantity: values.quantity, userId: user.id });
    }

    onClose();
  };

  const checkIfButtonEnable = (values: FormValues) =>
    values.quantity !== undefined || isMaxmumStockEdit;

  return (
    <FormLegacyRenderProp form={form} onFinish={onFinish} preserve={false}>
      {(values) => (
        <Modal
          title={
            <div className="header-modal">
              <div className="title">{`Edit${
                isMaxmumStockEdit ? ' maximum' : ''
              } stock`}</div>
              <div className="subtitle">{hotelStock.name}</div>
            </div>
          }
          className="activate-pause-modal"
          open={isOpen}
          onCancel={onClose}
          centered
          footer={[
            <Button type="ghost" className="close-button" onClick={onClose}>
              Close
            </Button>,
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              onClick={form.submit}
              className="saveBtn"
              disabled={!checkIfButtonEnable(values)}
            >
              Confirm
            </Button>,
          ]}
          destroyOnClose
        >
          <div className="resume-content">
            <div className="stocks-values">
              {!isMaxmumStockEdit ? (
                <>
                  <div className="text">Stock</div>
                  <Form.Item name={['quantity']} noStyle>
                    <CustomInput
                      onWheel={(e) => e.currentTarget.blur()}
                      min={0}
                      parser={parseInputNumber}
                      className="input"
                      placeholder="Enter a stock"
                      defaultValue={hotelStock.quantity}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <div>
                    <div className="text">Max stock</div>
                    <div className="description">
                      Maximum number of welcome kits that the hotel can store
                    </div>
                  </div>
                  <Form.Item name={['maxQuantity']} noStyle>
                    <CustomInput
                      onWheel={(e) => e.currentTarget.blur()}
                      min={0}
                      parser={parseInputNumber}
                      placeholder="Enter a max stock"
                      className="input"
                      defaultValue={hotelStock.maxQuantity}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </div>
        </Modal>
      )}
    </FormLegacyRenderProp>
  );
};

export default EditStockHotelModal;
