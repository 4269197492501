import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { useState } from 'react';

import {
  useCancelDelivery,
  useDeliveredDelivery,
  useDraftDelivery,
  useNotDeliveredDelivery,
} from 'app/hooks/data/welcomeKit/deliveries/useDeliveries';
import {
  Delivery,
  DeliveryStatus,
  OrderStatus,
} from 'app/typings/WelcomeKit/deliveries';

import { QuantityDeliveryModal } from './UpdateDeliveryQuantityModal/UpdateDeliveryQuantityModal';

type Props = {
  delivery: Delivery;
  orderStatus?: OrderStatus;
};

export const DeliveryMenuActions = ({ delivery, orderStatus }: Props) => {
  const { mutate: cancelDelivery } = useCancelDelivery(delivery.id);
  const { mutate: draftDelivery } = useDraftDelivery(delivery.id);
  const { mutate: deliverDelivery } = useDeliveredDelivery(delivery.id);
  const { mutate: notDeliverDelivery } = useNotDeliveredDelivery(delivery.id);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  if (
    !orderStatus ||
    orderStatus === OrderStatus.Cancelled ||
    (delivery.status === DeliveryStatus.Cancelled &&
      orderStatus === OrderStatus.Sent)
  ) {
    return <></>;
  }

  const renderMenu = () => {
    switch (delivery.status) {
      case DeliveryStatus.Cancelled:
        return (
          <Menu>
            <Menu.Item key="draft" onClick={() => draftDelivery()}>
              Set as "Draft"
            </Menu.Item>
          </Menu>
        );
      case DeliveryStatus.Delivered:
        return (
          <Menu>
            <Menu.Item key="not-delivered" onClick={() => notDeliverDelivery()}>
              Set as "Not delivered"
            </Menu.Item>
          </Menu>
        );
      case DeliveryStatus.Draft:
        return (
          <>
            <Menu>
              <Menu.Item key="edit" onClick={() => setModalOpen(true)}>
                Edit quantity
              </Menu.Item>
              <Menu.Item key="cancel" onClick={() => cancelDelivery()}>
                Set as "Cancelled"
              </Menu.Item>
            </Menu>
            <QuantityDeliveryModal
              deliveryId={delivery.id}
              currentDeliveryQuantity={delivery.quantity}
              hotel={delivery.hotel}
              isOpen={isModalOpen}
              closeModal={() => setModalOpen(false)}
              maxQuantity={delivery.hotel.maxQuantity}
            />
          </>
        );
      case DeliveryStatus.NotDelivered:
        return (
          <Menu>
            <Menu.Item key="deliver" onClick={() => deliverDelivery()}>
              Set as "Delivered"
            </Menu.Item>
          </Menu>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="actions-menu">
      <Dropdown overlay={renderMenu()} trigger={['click']}>
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );
};
