import { SortOrder } from 'app/utils/filters/filters';

import { PaginationContext } from '../pagination';

export type DeliveryCountry = {
  id: number;
  name: string;
  locale: string;
  slug: string;
  deliveriesOpen: boolean;
};

export enum DeliveryStatus {
  Draft = 'DRAFT',
  Delivered = 'DELIVERED',
  NotDelivered = 'NOT_DELIVERED',
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
}

export type Delivery = {
  id: number;
  quantity: number;
  status: DeliveryStatus;
  street: string;
  city: string;
  zipCode?: string;
  phone: string;
  countryId: number;
  hotel: { name: string; currentStock: number; maxQuantity?: number };
};

export type DeliveriesListParams = {
  status?: DeliveryStatus[];
  search?: string;
  sortOrder?: SortOrder;
  pageNumber?: number;
};

export type DeliveriesPayload = {
  data: Delivery[];
  meta: PaginationContext;
};

export enum OrderStatus {
  Draft = 'DRAFT',
  Sent = 'SENT',
  Cancelled = 'CANCELLED',
}

export type WelcomeKitOrder = {
  id: number;
  createdAt: string;
  status: OrderStatus;
  sentAt: string;
};
