import { Button, Form, Modal, Select } from 'antd';

import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { usePauseStocks } from 'app/hooks/data/welcomeKit/stocks/useStocks';
import {
  PAUSE_REASON,
  PauseReason,
  StockListItem,
} from 'app/typings/WelcomeKit/welcomeKit';

import './ActivatePausedDeliveryModal.scss';

const { Option } = Select;

type FormValues = {
  reason: PauseReason;
};

type Props = {
  hotelStock: StockListItem;
  isOpen: boolean;
  onClose: () => void;
};

const PausedDeliveryModal = ({ hotelStock, isOpen, onClose }: Props) => {
  const [form] = Form.useForm();

  const { mutate: pauseHotel } = usePauseStocks(hotelStock.hotelId);

  const onFinish = (values: FormValues) => {
    pauseHotel({ reason: values.reason });

    onClose();
  };

  return (
    <FormLegacyRenderProp form={form} onFinish={onFinish} preserve={false}>
      {(values) => (
        <Modal
          title={
            <div className="header-modal">
              <div className="title">Pause deliverability</div>
              <div className="subtitle">{hotelStock.name}</div>
            </div>
          }
          className="activate-pause-modal"
          open={isOpen}
          onCancel={onClose}
          centered
          footer={[
            <Button type="ghost" className="close-button" onClick={onClose}>
              Close
            </Button>,
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              onClick={form.submit}
              className="saveBtn"
              disabled={!values.reason}
            >
              Confirm
            </Button>,
          ]}
          destroyOnClose
        >
          <div className="pause-content">
            <div className="text">Why do you pause this hotel?</div>
            <Form.Item name={['reason']} noStyle>
              <Select placeholder="Select reason" style={{ width: '100%' }}>
                {Object.entries(PAUSE_REASON).map(([key, value]) => (
                  <Option value={key} key={key}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Modal>
      )}
    </FormLegacyRenderProp>
  );
};

export default PausedDeliveryModal;
