import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert } from 'antd';

const PackageUnpublishedAlert = () => {
  return (
    <div className="unpublished-package-alert-container">
      <Alert
        className="unpublished-package-alert"
        showIcon
        icon={<InfoCircleOutlined />}
        description={
          <>
            This package is currently unpublished on Staycation, users cannot
            see it.
            <br />
            Contact your AM to publish it.
          </>
        }
        message="Unpublished package"
      />
    </div>
  );
};

export default PackageUnpublishedAlert;
