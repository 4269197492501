import { AppstoreAddOutlined } from '@ant-design/icons';

import TableRow from 'app/components/commons/TableRow/TableRow';
import TableRows from 'app/components/commons/TableRow/TableRows';
import { BedCategory, RoomCategory } from 'app/typings';
import { ExtranetRoom } from 'app/typings/packages';

import EmptyState from '../../commons/EmptyState/EmptyState';
import {
  formatRoomTitle,
  formattedBeds,
  formattedRoomValue,
} from '../commons/Utils';

import { SetActiveDataModalType } from './TableRowExperiences';

type TableRowRoomsProps = {
  title: 'Rooms' | 'Slots';
  isDayPackage?: boolean;
  rooms: ExtranetRoom[];
  roomCategories: RoomCategory[];
  bedCategories: BedCategory[];
  setActiveDataModal?: SetActiveDataModalType;
  setIsDetailModalOpen?: (modalOpen: boolean) => void;
};

const TableRowRooms = ({
  title,
  isDayPackage = false,
  rooms,
  roomCategories,
  bedCategories,
  setActiveDataModal,
  setIsDetailModalOpen,
}: TableRowRoomsProps) => {
  return (
    <TableRows title={title}>
      {rooms.length > 0 ? (
        rooms.map((room) => (
          <TableRow
            key={room.id}
            title={
              formatRoomTitle(
                room.name,
                room.categoryId,
                roomCategories ?? []
              ) ?? ''
            }
            description={
              room.name
                ? roomCategories?.find((exp) => exp.id === room.categoryId)
                    ?.name
                : undefined
            }
            coverPicturePath={room.coverPicture.path}
            imageType="image"
            primaryInfo={
              !isDayPackage
                ? formattedBeds(room.beds, bedCategories ?? [])
                : undefined
            }
            secondaryInfo={formattedRoomValue(room.beds, bedCategories ?? [])}
            onClick={() => {
              if (setActiveDataModal && setIsDetailModalOpen) {
                setActiveDataModal({ type: 'room', data: room });
                setIsDetailModalOpen(true);
              }
            }}
          />
        ))
      ) : (
        <EmptyState
          title="No published rooms"
          description="Please contact your Account Manager for more information."
          icon={<AppstoreAddOutlined />}
        />
      )}
    </TableRows>
  );
};

export default TableRowRooms;
