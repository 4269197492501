import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import type { Picture } from 'app/typings/picture';
import { formatEmojiList } from 'app/utils/emoji';
import { apiRequest } from 'app/utils/request/api';

const path = '/pictures/emojis' as const;

export function useEmojis() {
  const result = useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<Picture[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });

  return useMemo(() => {
    if (result.data && Array.isArray(result.data)) {
      return {
        ...result,
        data: formatEmojiList(result.data),
      };
    }

    return result;
  }, [result]);
}
