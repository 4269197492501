import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { PauseReason } from 'app/typings/WelcomeKit/welcomeKit';
import { apiMutation } from 'app/utils/request/api';

import { pathStocks } from '../useGetWelcomeKitLists';

const pausePath =
  '/gateway/hotel-logistics/welcome-kit/hotel-stocks/:hotelId/pause' as const;
const resumePath =
  '/gateway/hotel-logistics/welcome-kit/hotel-stocks/:hotelId/resume' as const;
const editPath =
  '/gateway/hotel-logistics/welcome-kit/hotel-stocks/:hotelId/quantity' as const;
const editMaximumPath =
  '/gateway/hotel-logistics/welcome-kit/hotel-stocks/:hotelId/max-quantity' as const;

export function usePauseStocks(hotelId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { reason: PauseReason }) =>
      apiMutation(
        'POST',
        {
          path: pausePath,
          params: { hotelId: hotelId.toString() },
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      queryClient.invalidateQueries([pathStocks]);
      message.success(`Hotel #${hotelId} deliverability successfully paused`);
    },
    onError: () => {
      message.error('Error paused deliverability');
    },
  });
}

export function useResumeStocks() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (hotelId: number) =>
      apiMutation('POST', {
        path: resumePath,
        params: { hotelId: hotelId.toString() },
      }) as Promise<void>,
    onSuccess: (_, hotelId) => {
      queryClient.invalidateQueries([pathStocks]);
      message.success(`Hotel #${hotelId} deliverability successfully resumed`);
    },
    onError: () => {
      message.error('Error resume deliverability');
    },
  });
}

export function useEditHotelStocks(hotelId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { quantity: number; userId: number }) =>
      apiMutation(
        'PUT',
        {
          path: editPath,
          params: { hotelId: hotelId.toString() },
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      queryClient.invalidateQueries([pathStocks]);
      message.success(`Hotel #${hotelId} stocks successfully edited`);
    },
    onError: () => {
      message.error('Error edit stocks');
    },
  });
}

export function useEditMaxStocks(hotelId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { maxQuantity?: number }) =>
      apiMutation(
        'PATCH',
        {
          path: editMaximumPath,
          params: { hotelId: hotelId.toString() },
        },
        payload
      ) as Promise<void>,
    onSuccess: () => {
      queryClient.invalidateQueries([pathStocks]);
      message.success(`Hotel #${hotelId} maximum stocks successfully edited`);
    },
    onError: () => {
      message.error('Error edit maximum stocks');
    },
  });
}
