import { computeExperienceName } from 'app/components/commons/Experience/utils';
import { useRoomCategories } from 'app/hooks/data/rooms/useRoomCategories';
import { useExperiencesCategories } from 'app/hooks/data/useExperience';
import { ExtranetExperience, ExtranetRoom } from 'app/typings/packages';

import { formatRoomTitle } from '../../commons/Utils';

type PackageModalTitleProps =
  | {
      type: 'experience';
      data: ExtranetExperience;
    }
  | { type: 'room'; data: ExtranetRoom };

const PackageModalTitle = ({ type, data }: PackageModalTitleProps) => {
  const { data: roomCategories } = useRoomCategories();
  const { data: experienceCategories } = useExperiencesCategories();

  if (type === 'experience') {
    const experience = data;

    return (
      <>
        {computeExperienceName({
          category: experienceCategories?.find(
            (cat) => cat?.id === experience.categoryId
          ),
          quantity: experience.quantity ?? null,
          brand: experience.brand ?? null,
          duration: experience.duration ?? null,
          durationDescription: experience.durationDescription ?? null,
          time: experience.time ?? '',
          locations: experience.locations ?? null,
        })}
      </>
    );
  }

  const room = data;

  return (
    <>
      {formatRoomTitle(room.name, room.categoryId, roomCategories ?? []) ?? ''}
    </>
  );
};

export default PackageModalTitle;
