import { useQuery } from '@tanstack/react-query';

import {
  Meta,
  OrderListItem,
  OrdersListQueryParam,
  StockListItem,
  StocksListQueryParam,
} from 'app/typings/WelcomeKit/welcomeKit';
import { apiRequest } from 'app/utils/request/api';

export const pathStocks =
  '/gateway/hotel-logistics/welcome-kit/hotel-stocks' as const;

export const pathOrders =
  '/gateway/hotel-logistics/welcome-kit/orders' as const;

export function useGetStocksList(queryParams: StocksListQueryParam) {
  const { data, isLoading } = useQuery({
    queryKey: [pathStocks, { queryParams }],
    queryFn: () =>
      apiRequest({
        path: pathStocks,
        queryParams,
      }) as Promise<{ data: StockListItem[]; meta: Meta }>,
  });

  return {
    data,
    isLoading,
  };
}

export function useGetOrderList(queryParams: OrdersListQueryParam) {
  const { data, isLoading } = useQuery({
    queryKey: [pathOrders, { queryParams }],
    queryFn: () =>
      apiRequest({
        path: pathOrders,
        queryParams,
      }) as Promise<OrderListItem[]>,
  });

  return {
    data,
    isLoading,
  };
}
