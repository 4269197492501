import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import {
  DeliveriesListParams,
  DeliveriesPayload,
} from 'app/typings/WelcomeKit/deliveries';
import { apiMutation, apiRequest } from 'app/utils/request/api';

import { deliverableHotelsPath } from './useDeliveryHotels';

const path =
  '/gateway/hotel-logistics/welcome-kit/orders/:orderId/deliveries' as const;
const updatePath = (action: string) =>
  `/gateway/hotel-logistics/welcome-kit/deliveries/:deliveryId/${action}` as const;
const updateQuantityPath =
  '/gateway/hotel-logistics/welcome-kit/deliveries/:deliveryId/quantity' as const;
const createDeliveryPath =
  'gateway/hotel-logistics/welcome-kit/orders/:orderId/deliveries' as const;

export function useDeliveries(
  orderId: number,
  countryId: number,
  params: DeliveriesListParams
) {
  const queryKey = [path, { countryId, params }];

  return useQuery({
    queryKey,
    queryFn: () =>
      apiRequest({
        path,
        params: { orderId: orderId.toString() },
        queryParams: { countryId, ...params },
      }) as Promise<DeliveriesPayload>,
    onError: () => {
      message.error('Error fetching Deliveries');
    },
  });
}

function useUpdateDeliveryStatus(deliveryId: number, status: string) {
  const queryClient = useQueryClient();
  const updateStatusPath = updatePath(status);

  return useMutation({
    mutationFn: async () =>
      apiMutation('POST', {
        path: updateStatusPath,
        params: { deliveryId: deliveryId.toString() },
      }) as Promise<void>,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [path] });
      message.success('Delivery status updated');
    },
    onError: () => {
      message.error('Error updating delivery status');
    },
  });
}

export function useCancelDelivery(deliveryId: number) {
  return useUpdateDeliveryStatus(deliveryId, 'cancel');
}

export function useDraftDelivery(deliveryId: number) {
  return useUpdateDeliveryStatus(deliveryId, 'draft');
}

export function useDeliveredDelivery(deliveryId: number) {
  return useUpdateDeliveryStatus(deliveryId, 'delivered');
}

export function useNotDeliveredDelivery(deliveryId: number) {
  return useUpdateDeliveryStatus(deliveryId, 'not-delivered');
}

export function useUpdateDeliveryQuantity(deliveryId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (quantity: number) =>
      apiMutation(
        'PATCH',
        {
          path: updateQuantityPath,
          params: { deliveryId: deliveryId.toString() },
        },
        { quantity }
      ) as Promise<void>,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [path] });
      message.success('Delivery quantity updated');
    },
    onError: () => {
      message.error('Error updating delivery quantity');
    },
  });
}

export function useCreateDelivery(orderId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (delivery: { hotelId: number; quantity: number }) =>
      apiMutation(
        'POST',
        {
          path: createDeliveryPath,
          params: { orderId: orderId.toString() },
        },
        delivery
      ) as Promise<void>,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [path],
      });
      queryClient.invalidateQueries({
        queryKey: [deliverableHotelsPath],
      });
      message.success('Delivery created');
    },
    onError: () => {
      message.error('Error creating delivery');
    },
  });
}
