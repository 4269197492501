import { AppstoreAddOutlined } from '@ant-design/icons';
import { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { computeExperienceName } from 'app/components/commons/Experience/utils';
import TableRow from 'app/components/commons/TableRow/TableRow';
import TableRows from 'app/components/commons/TableRow/TableRows';
import { ExperienceCategory } from 'app/typings/experiences';
import { ExtranetExperience } from 'app/typings/packages';

import EmptyState from '../../commons/EmptyState/EmptyState';
import { formattedExpAddOnRules, formattedPriceValues } from '../commons/Utils';

import { PackageDetailProps } from './PackageModal/PackageDetail';

type TableRowExperiencesTitle = 'Experiences' | 'Add-ons';

export type SetActiveDataModalType = React.Dispatch<
  React.SetStateAction<PackageDetailProps | undefined>
>;

type TableRowExperiencesProps = {
  title: TableRowExperiencesTitle;
  isDayPackage?: boolean;
  experiencesList: ExtranetExperience[];
  experienceCategories?: ExperienceCategory[];
  setActiveDataModal?: SetActiveDataModalType;
  setIsDetailModalOpen?: (modalOpen: boolean) => void;
};

const TableRowExperiences = ({
  title,
  isDayPackage = false,
  experiencesList,
  experienceCategories,
  setActiveDataModal,
  setIsDetailModalOpen,
}: TableRowExperiencesProps) => {
  const { currencyFormatter } = useContext(CurrencyContext);
  const isAddOn = title === 'Add-ons';

  return (
    <TableRows title={title}>
      {experiencesList.length > 0 ? (
        experiencesList.map((exp) => (
          <TableRow
            key={exp.id}
            title={computeExperienceName({
              category: experienceCategories?.find(
                (cat) => cat?.id === exp.categoryId
              ),
              quantity: exp.quantity,
              brand: exp.brand,
              duration: exp.duration,
              durationDescription: exp.durationDescription,
              time: exp.time,
              locations: exp.locations,
            })}
            coverPicturePath={
              (isAddOn ? exp.emoji?.path : exp.coverPicture?.path) ?? ''
            }
            imageType={isAddOn ? 'emoji' : 'image'}
            primaryInfo={formattedExpAddOnRules(exp)}
            secondaryInfo={
              !isDayPackage || isAddOn
                ? formattedPriceValues(
                    exp.price,
                    exp.priceDescription,
                    currencyFormatter
                  )
                : undefined
            }
            onClick={() => {
              if (setActiveDataModal && setIsDetailModalOpen) {
                setActiveDataModal({ type: 'experience', data: exp });
                setIsDetailModalOpen(true);
              }
            }}
          />
        ))
      ) : (
        <EmptyState
          title={`No published ${isAddOn ? 'add-ons' : 'experiences'}`}
          description={`Please contact your Account Manager for more information.`}
          icon={<AppstoreAddOutlined />}
        />
      )}
    </TableRows>
  );
};

export default TableRowExperiences;
