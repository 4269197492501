import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { ExtranetPackage } from 'app/typings/packages';
import { apiRequest } from 'app/utils/request/api';

const path = '/hotel-admin/:hotelId/packages' as const;

export function useGetHotelPackages(hotelId: number) {
  return useQuery({
    queryKey: [path, { hotelId }],
    queryFn: () =>
      apiRequest({ path, params: { hotelId: hotelId.toString() } }) as Promise<{
        packages: ExtranetPackage[];
      }>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!hotelId,
    onError: () => {
      message.error('Error fetching hotel');
    },
  });
}
