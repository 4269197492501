import { CheckOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

export type Rule = {
  type: string;
  value: string | number;
  icon: ReactNode;
  description: ReactNode;
};

type PackageDetailRuleProps = {
  rule: Rule;
};

const PackageDetailRule = ({ rule }: PackageDetailRuleProps) => {
  return (
    <div className="package-detail-rule">
      <div className="rule-icon">{rule.icon}</div>
      <div className="rule-texts">
        <div className="title">{rule.type}</div>
        <div className="description">{rule.description}</div>
      </div>
      <div className="rule-check">
        <CheckOutlined />
      </div>
    </div>
  );
};

export default PackageDetailRule;
