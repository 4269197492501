import { Form, Switch } from 'antd';
import { useState } from 'react';
import type { ReactElement } from 'react';

import './ToggleableInput.scss';

type ToggleableInputProps = {
  value?: number;
  children?: ReactElement;
  disabled?: boolean;
  name: string;
};

const ToggleableInput = ({
  name,
  value,
  children,
  disabled,
}: ToggleableInputProps) => {
  const [switchValue, setSwitchValue] = useState<boolean>(!!value);
  const form = Form.useFormInstance();

  const handleSwitchChange = (checked: boolean) => {
    setSwitchValue(checked);

    if (!checked) {
      form.setFieldValue(name, undefined);
    }
  };

  return (
    <div className="toggleableInput">
      <Form.Item noStyle name={'switch'}>
        <Switch
          checked={switchValue}
          onChange={handleSwitchChange}
          disabled={disabled}
        />
      </Form.Item>
      {switchValue && children && (
        <div className="inputContent">{children}</div>
      )}
    </div>
  );
};

export default ToggleableInput;
