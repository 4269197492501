import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { WelcomeKitOrder } from 'app/typings/WelcomeKit/deliveries';
import { apiRequest } from 'app/utils/request/api';

const path = '/gateway/hotel-logistics/welcome-kit/orders/:orderId' as const;

export function useWelcomeKitOrder(orderId: number) {
  return useQuery({
    queryKey: [path],
    queryFn: () =>
      apiRequest({
        path,
        params: { orderId: orderId.toString() },
      }) as Promise<WelcomeKitOrder>,
    onError: () => {
      message.error('Error fetching Order');
    },
  });
}
