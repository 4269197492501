import { ReactNode } from 'react';

import './TableRows.scss';

type TableRowsProps = { children: ReactNode; title: string };

const TableRows = ({ children, title }: TableRowsProps) => {
  return (
    <div className="custom-table">
      <div className="title">{title}</div>
      <div className="table-rows">{children}</div>
    </div>
  );
};

export default TableRows;
