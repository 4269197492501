import {
  DownloadOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Input, Layout, Tabs, TabsProps } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SHeader } from 'app/components/StaycationUI';
import TagWithIcon from 'app/components/commons/TagWithIcon/TagWithIcon';
import { useDeliveries } from 'app/hooks/data/welcomeKit/deliveries/useDeliveries';
import { useWelcomeKitOrder } from 'app/hooks/data/welcomeKit/deliveries/useDeliveriesOrder';
import { useDeliveriesCountries } from 'app/hooks/data/welcomeKit/deliveries/useDeliveryCountries';
import { useDownloadOrder } from 'app/hooks/data/welcomeKit/orders/useDownloadOrder';
import { useConfirmOrder } from 'app/hooks/data/welcomeKit/orders/useOrder';
import {
  DeliveriesListParams,
  DeliveryCountry,
  DeliveryStatus,
  OrderStatus,
} from 'app/typings/WelcomeKit/deliveries';
import { renderDate } from 'app/utils/dates';
import { upperCaseFirstLetter } from 'app/utils/strings';

import ConfirmOrderModal from '../Orders/Modals/ConfirmOrderModal/ConfirmOrderModal';

import { CreateDeliveryModal } from './CreateDeliveryModal/CreateDeliveryModal';
import './Deliveries.scss';
import { DeliveriesList } from './DeliveriesList';

type Props = {
  match: {
    params: {
      id: string;
    };
  };
};

const emptyCountry: DeliveryCountry = {
  id: 1,
  name: '',
  locale: '',
  slug: '',
  deliveriesOpen: true,
};

const initialStatuses: DeliveryStatus[] = [
  DeliveryStatus.Cancelled,
  DeliveryStatus.Delivered,
  DeliveryStatus.Draft,
  DeliveryStatus.NotDelivered,
  DeliveryStatus.Pending,
];

const initialParams: DeliveriesListParams = {
  status: initialStatuses,
  pageNumber: 1,
  search: undefined,
  sortOrder: undefined,
};

export const Deliveries = ({ match }: Props) => {
  const history = useHistory();
  const orderId: number = parseInt(match.params.id, 10);

  const {
    data: order,
    isLoading: orderLoading,
    refetch: reloadOrder,
    remove: removeOrder,
  } = useWelcomeKitOrder(orderId);
  const { data: countries, isLoading: countriesLoading } =
    useDeliveriesCountries();
  const { mutateAsync: confirmOrder } = useConfirmOrder();
  const { downloadOrder } = useDownloadOrder();
  const [listParams, setListParams] =
    useState<DeliveriesListParams>(initialParams);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [orderIdConfirm, setOrderIdConfirm] = useState<number>();

  const initialCountry: DeliveryCountry = countries?.[0] ?? emptyCountry;

  const [selectedCountry, setSelectedCountry] =
    useState<DeliveryCountry>(initialCountry);

  const {
    data: deliveries,
    isLoading: loading,
    refetch: reloadDeliveries,
  } = useDeliveries(orderId, selectedCountry.id, listParams);

  const renderTitle = () => {
    if (!order?.status) {
      return <></>;
    }

    const renderTag = () => {
      const iconType: 'success' | 'default' | 'error' | 'warning' =
        order?.status === OrderStatus.Sent
          ? 'success'
          : order?.status === OrderStatus.Draft
          ? 'warning'
          : 'default';

      const iconLabel = upperCaseFirstLetter(order?.status.toLocaleLowerCase());

      return <TagWithIcon text={iconLabel} type={iconType} />;
    };

    return (
      <div>
        Order {renderDate(order?.createdAt, 'dd/MM/yyyy')}
        <span className="header-tag">{renderTag()}</span>
      </div>
    );
  };

  const renderOrderAction = () => {
    if (order?.status === OrderStatus.Sent) {
      return (
        <Button
          key="download-button"
          icon={<DownloadOutlined />}
          onClick={() => downloadOrder(orderId, order?.sentAt)}
        >
          Download .xls
        </Button>
      );
    } else if (order?.status === OrderStatus.Draft) {
      return (
        <Button
          key="confirm-button"
          type="primary"
          onClick={() => setOrderIdConfirm(orderId)}
        >
          Confirm
        </Button>
      );
    }

    return;
  };

  const renderExtraActions = () => {
    const searchInput = (
      <Input
        key={`search-input`}
        placeholder="Search"
        value={listParams.search}
        allowClear
        onChange={(event) => {
          setListParams({ ...listParams, search: event.target.value });
        }}
        suffix={<SearchOutlined />}
        className="delivery-search-input"
      />
    );

    if (order?.status === OrderStatus.Draft) {
      return (
        <div className="delivery-extra-content">
          <Button icon={<PlusOutlined />} onClick={() => setModalOpen(true)}>
            Add new hotel delivery
          </Button>
          {searchInput}
        </div>
      );
    }

    return <div className="delivery-extra-content">{searchInput}</div>;
  };

  const onBack = () => {
    history.push('/welcome-kit?category=orders');
    removeOrder();
  };

  const renderLayout = (content?: ReactNode) => (
    <Layout className="page-list">
      <SHeader
        title={renderTitle()}
        extra={[renderOrderAction()]}
        onBack={onBack}
      />
      <Content className="deliveries-container">{content}</Content>
    </Layout>
  );

  if (!countries || !countries[0]) {
    return renderLayout();
  }

  const getTabs: () => TabsProps['items'] = () => {
    const countriesItems = countries
      .sort((a, b) => a.id - b.id)
      .map((c) => ({
        key: c.id.toString(),
        label: c.name,
        children: (
          <DeliveriesList
            items={deliveries?.data || []}
            pagination={deliveries?.meta}
            loading={countriesLoading || loading || orderLoading}
            params={listParams}
            setParams={setListParams}
            orderStatus={order?.status}
          />
        ),
      }));

    return countriesItems;
  };

  const onSwitchTab = (key: string) => {
    const selectedCountryId = parseInt(key, 10);
    const selectedCountry = countries.find((c) => c.id === selectedCountryId);

    setListParams(initialParams);

    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
    }
  };

  return renderLayout(
    <>
      <Tabs
        onChange={onSwitchTab}
        items={getTabs()}
        defaultActiveKey={selectedCountry.id.toString()}
        className="delivery-tabs"
        tabBarExtraContent={renderExtraActions()}
      />
      {order?.id && (
        <CreateDeliveryModal
          countryId={selectedCountry.id}
          isOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          orderId={order.id}
        />
      )}
      {orderIdConfirm && (
        <ConfirmOrderModal
          isOpen={!!orderIdConfirm}
          onClose={() => setOrderIdConfirm(undefined)}
          onSubmit={async () => {
            await confirmOrder(orderIdConfirm);
            await setOrderIdConfirm(undefined);
            await reloadOrder();
            await reloadDeliveries();
          }}
        />
      )}
    </>
  );
};
