import {
  CalendarOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  MessageOutlined,
  PushpinOutlined,
  RightOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { Input, Layout, Menu, Modal, Tooltip, message } from 'antd';
import { differenceInCalendarDays } from 'date-fns';
import _ from 'lodash';
import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import Pic from 'app/components/commons/Pic';
import { ExtranetLogo } from 'app/components/commons/icons/ExtranetLogo';
import { Inventory } from 'app/components/pages/Extranet/ExtranetInventory/Types';
import { useAdminUserById } from 'app/hooks/data/useAdminUser';
import { useAmplitude } from 'app/hooks/useAmplitude/useAmplitude';
import { updateHotelId } from 'app/redux/actions/hotelAdmin';
import { useAppSelector } from 'app/redux/hooks';
import { Country } from 'app/redux/models/Country/Country';
import copyToClipboard from 'app/utils/clipboard';
import { getNewExtranetUrl } from 'app/utils/extranet';

import 'styles/layout/side-menu.scss';

import { Tag } from '../commons/Tag/Tag';
import { numberToStars } from '../pages/Bookings/details/NumberToStars';

import './SideMenuHotel.scss';

const { Sider } = Layout;
const { Search } = Input;

type MenuItems = {
  [key: string]: {
    title: string;
    link?: string;
    icon: React.ReactElement;
    absolute?: boolean;
    tag?: React.ReactElement;
    amplitudeEvent?: {
      name: string;
      content: object;
    };
  };
};

type Hotel = {
  id: number;
  name: string;
  countryId: number;
  stars?: number;
  managerId?: number;
};

type HotelDAO = {
  id: number;
  name: string;
  address: {
    countryId: number;
  };
  stars?: number;
  managerId?: number;
};

function isHotelDao(hotel: Hotel | HotelDAO): hotel is HotelDAO {
  return 'address' in hotel;
}

export const SideMenuHotel: FC<Props> = ({
  user,
  hotels,
  hotelId,
  setHotelId,
  inventory,
  appUrl,
  apiUrl,
  token,
  countries,
}) => {
  const HIDE_NEW_TAG_AFTER_X_DAYS = 30;

  const selectedHotel = hotels.find((hotel) => hotel.id === hotelId);
  const countryId =
    selectedHotel && isHotelDao(selectedHotel)
      ? selectedHotel?.address?.countryId
      : selectedHotel?.countryId;
  const country = countries.find((c) => c.id === countryId);
  const { track } = useAmplitude();

  const { data: manager } = useAdminUserById(selectedHotel?.managerId);

  const { pathname } = useAppSelector((s) => s.router.location);
  const menuItems: MenuItems = {
    extranetInventory: {
      title: 'Inventory',
      link: `/extranet/inventory?hotelId=${hotelId}`,
      icon: <CalendarOutlined />,
    },
    extranetDashboard: {
      title: 'Dashboard',
      link: `/extranet/dashboard?hotelId=${hotelId}`,
      icon: <LineChartOutlined />,
      tag:
        differenceInCalendarDays(new Date(), new Date(2024, 3, 29)) <
        HIDE_NEW_TAG_AFTER_X_DAYS ? (
          <Tag text={'New'} />
        ) : undefined,
    },
    extranetBookings: {
      title: 'Bookings',
      link: `/extranet/bookings?hotelId=${hotelId}`,
      icon: <ShoppingOutlined />,
    },
    extranetBills: {
      title: 'Bills',
      link: `/extranet/bills?hotelId=${hotelId}`,
      icon: <FolderOpenOutlined />,
    },
    extranetReviews: {
      title: 'Reviews',
      link: `/extranet/reviews?hotelId=${hotelId}`,
      icon: <MessageOutlined />,
    },
    packageDetail: {
      title: 'Packages',
      link: `/extranet/packages?hotelId=${hotelId}`,
      icon: <PushpinOutlined />,
    },
  };

  if (
    hotelId &&
    token &&
    country?.stripeActivated &&
    ((user.role === 'user' && user.hotelIds && user.hotelIds.length) ||
      user.role === 'superadmin')
  ) {
    menuItems.stripe = {
      title: 'Payment',
      link: `${apiUrl}/hotels/${hotelId}/stripe-session?token=${token}`,
      absolute: true,
      icon: <CreditCardOutlined />,
    };
  }

  menuItems.contact = {
    title: 'Contact',
    link: `/extranet/contact?hotelId=${hotelId}`,
    icon: <ContactsOutlined />,
  };

  const selectedMenuKey =
    Object.keys(menuItems).find((key) =>
      menuItems[key].link?.includes(pathname)
    ) ?? '';
  const [openKeys, setOpenKeys] = useState<Array<string>>([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [searchText, setSearchText] = useState('');

  const closeModal = () => {
    setModalOpened(false);
    setSearchText('');
  };

  const handleOnClick = (hotel: any) => {
    track('Click on switch hotels', { hotelId: hotel.id });

    const path = getNewExtranetUrl(hotel.id);

    window.history.pushState({ path: path }, '', path);
    setHotelId(hotel.id);
    closeModal();
  };

  const onItemClick = (item: any) => {
    if (item.amplitudeEvent) {
      track(item.amplitudeEvent.name, item.amplitudeEvent.content);
    }

    window.open(item.link);
  };

  const renderSubMenu = (key: any, item: any) => (
    <Menu.Item className="standalone-item" key={key}>
      {!item.absolute ? (
        <NavLink to={item.link}>
          <div className="subMenuElement">
            <span className="subMenuIcon">{item.icon}</span>
            {item.title}
            {item.tag && <div className="subTagNew">{item.tag}</div>}
          </div>
        </NavLink>
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={() => onItemClick(item)}>
          <div className="subMenuElement">
            <span className="subMenuIcon">{item.icon}</span>
            {item.title}
          </div>
        </a>
      )}
    </Menu.Item>
  );

  const match = searchText.toLowerCase();

  const filteredHotels = hotels.filter((hotel) =>
    hotel.name.toLowerCase().includes(match)
  );
  const sortedHotels = _.sortBy(filteredHotels, (hotel) =>
    hotel.name.toLowerCase()
  );

  const hotelName = () => (
    <div className="selector-title">
      {!!selectedHotel && `${selectedHotel.name} `}
      {!!selectedHotel &&
        !!selectedHotel.stars &&
        !!(selectedHotel.stars !== 0) &&
        numberToStars(selectedHotel.stars)}
    </div>
  );

  const getLastNameInitials = (lastName: string) => {
    const lastNameParts = lastName.trim().split(' ');

    return lastNameParts.map((name) => name[0]).join('');
  };

  const handleCopy = (email: string) => {
    copyToClipboard(
      email,
      message.info({
        content: 'Email copied successfully!',
        icon: <InfoCircleOutlined style={{ color: '#808080' }} />,
      })
    );
  };

  return (
    <Sider className="sider">
      <div>
        <Link to="/extranet">
          <div className="title">
            <ExtranetLogo />
          </div>
        </Link>
        {hotels.length > 1 ? (
          <div onClick={() => setModalOpened(true)} className="selector">
            <div className="selector-div">
              {hotelName()}
              <div>
                <RightOutlined />
              </div>
            </div>
          </div>
        ) : (
          <div className="selector-hotelier">
            <div className="selector-div">{hotelName()}</div>
          </div>
        )}
        <Menu
          openKeys={openKeys}
          onOpenChange={(elem) => setOpenKeys(elem)}
          theme="dark"
          className="menu"
          mode="inline"
          selectedKeys={[selectedMenuKey]}
        >
          {Object.keys(menuItems).map((key) =>
            renderSubMenu(key, menuItems[key])
          )}
        </Menu>
      </div>
      {manager && selectedHotel && (
        <div className="bottom_extranet">
          <div className="title-ac">Your account manager</div>
          <div className="info-ac">
            {manager.firstName && manager.lastName && (
              <div className="name-avatar-ac">
                <Pic
                  className="avatar"
                  style={{ borderRadius: '50%' }}
                  pictureId={manager.avatarId || 'static/default-avatar.png'}
                />
                <div className="name-ac">
                  {manager.firstName || `#${manager.id}`}{' '}
                  {getLastNameInitials(manager.lastName)}
                </div>
              </div>
            )}
            <Tooltip title={manager.email}>
              <div
                className="email-ac"
                onClick={() => handleCopy(manager.email)}
              >
                {manager.email}
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      <Modal
        className="sider-hotel-popup"
        visible={modalOpened}
        title={
          <Search
            placeholder="Search..."
            style={{ width: '512px' }}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
        }
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        <div>
          {sortedHotels.map((hotel) => (
            <div
              className="popup-section"
              key={hotel.id}
              onClick={() => handleOnClick(hotel)}
            >
              <div className="section-text">{hotel.name}</div>
              <div className="section-icon">
                <RightOutlined />
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </Sider>
  );
};

type User = {
  firstName: string;
  email: string;
  role: string;
  avatarId?: string;
  hotelIds?: number[];
  id: number;
};

type Props = {
  user: User;
  hotels: (Hotel | HotelDAO)[];
  hotelId: number;
  setHotelId: (hotelId: number) => void;
  fetchInventory: (hotelId: number) => void;
  inventory: Inventory;
  appUrl: string;
  apiUrl: string;
  token: string;
  countries: Country[];
};

const mapStateToProps = (state: any) => ({
  hotelId: state.hotelAdmin.hotelId,
  hotels: state.hotelAdmin.hotels,
  inventory: state.hotelAdmin.inventory,
  appUrl: state.navigation.appUrl,
  apiUrl: state.navigation.apiUrl,
  token: state.auth.token,
  countries: state.conf.countries,
});

const mapDispatchToProps = (dispatch: any) => ({
  setHotelId: (hotelId: any) => dispatch(updateHotelId({ hotelId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuHotel);
