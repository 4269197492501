import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { DeliveryCountry } from 'app/typings/WelcomeKit/deliveries';
import { apiRequest } from 'app/utils/request/api';

const path = '/gateway/hotel-logistics/countries' as const;

export function useDeliveriesCountries() {
  return useQuery({
    queryKey: [path],
    queryFn: () =>
      apiRequest({
        path,
        queryParams: { deliveriesOpen: true },
      }) as Promise<DeliveryCountry[]>,
    onError: () => {
      message.error('Error fetching Delivery Countries');
    },
    staleTime: Infinity,
  });
}
