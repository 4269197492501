import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { apiMutation } from 'app/utils/request/api';

import { pathOrders } from '../useGetWelcomeKitLists';

const newOrderPath = '/gateway/hotel-logistics/welcome-kit/orders' as const;
const confirmPath =
  '/gateway/hotel-logistics/welcome-kit/orders/:orderId/confirm' as const;

export function useCreateNewOrder() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      apiMutation('POST', {
        path: newOrderPath,
      }) as Promise<void>,
    onSuccess: () => {
      queryClient.invalidateQueries([pathOrders]);
      message.success(`New order successfully created`);
    },
    onError: () => {
      message.error('Error when creating new order');
    },
  });
}

export function useConfirmOrder() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (orderId: number) =>
      apiMutation('POST', {
        path: confirmPath,
        params: { orderId: orderId.toString() },
      }) as Promise<void>,
    onSuccess: () => {
      queryClient.invalidateQueries([pathOrders]);
      message.success(`Order successfully confirmed`);
    },
    onError: () => {
      message.error('Error when confirm order');
    },
  });
}
