import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Modal } from 'antd';
import { useState } from 'react';

import { Select } from 'app/components/fields/Select';
import { useCreateDelivery } from 'app/hooks/data/welcomeKit/deliveries/useDeliveries';
import { useDeliverableHotels } from 'app/hooks/data/welcomeKit/deliveries/useDeliveryHotels';

import './CreateDeliveryModal.scss';

type Props = {
  countryId: number;
  orderId: number;
  isOpen: boolean;
  closeModal: () => void;
};

export const CreateDeliveryModal = ({
  countryId,
  orderId,
  isOpen,
  closeModal,
}: Props) => {
  const { mutate: createDelivery } = useCreateDelivery(orderId);
  const { data: hotels } = useDeliverableHotels(orderId);
  const [currentQuantity, setCurrentQuantity] = useState<number>();
  const [selectedHotelId, setSelectedHotelId] = useState<number>();

  const canSave =
    currentQuantity !== undefined && selectedHotelId !== undefined;

  const selectedHotel = hotels?.find((h) => h.id === selectedHotelId);

  const hotelOptions = hotels
    ?.filter((h) => h.countryId === countryId)
    .map((h) => ({
      value: h.id,
      label: h.name,
    }));

  const onCancel = () => {
    setCurrentQuantity(undefined);
    setSelectedHotelId(undefined);
    closeModal();
  };

  const onConfirm = () => {
    if (canSave) {
      createDelivery({
        hotelId: selectedHotelId ?? 0,
        quantity: currentQuantity ?? 1,
      });
    }

    onCancel();
  };

  const renderFooter = () => {
    return (
      <>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          key="save"
          type="primary"
          onClick={() => onConfirm()}
          disabled={!canSave}
        >
          Save
        </Button>
      </>
    );
  };

  const renderStockEstimate = () => {
    if (selectedHotelId === undefined) {
      return <></>;
    }

    return (
      <div className="quantity-segment">
        <div>Hotel stock after delivery:</div>
        <span className="quantity-span">
          {selectedHotel?.currentStock || 0}
          {selectedHotel && currentQuantity && currentQuantity !== 0 && (
            <span className="added-quantity">
              {` + ${currentQuantity} = ${
                selectedHotel.currentStock + currentQuantity
              }`}
            </span>
          )}
        </span>
      </div>
    );
  };

  return (
    <Modal
      open={isOpen}
      title="Add new hotel delivery"
      onCancel={onCancel}
      footer={renderFooter()}
      width={600}
    >
      <div className="select-container">
        <label>Hotel name</label>
        <Select
          placeholder="Select hotel name"
          value={selectedHotelId}
          onChange={(value: number) => setSelectedHotelId(value)}
          options={hotelOptions}
          filterOption={(input, option) =>
            (option?.label ?? '')
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          showSearch
        />
      </div>
      {selectedHotel?.pauseWelcomeKit && (
        <Alert
          className="hotel-alert"
          type="info"
          message="Paused Hotel"
          description={`This hotel is marked as “paused”, meaning it is not normally supposed to receive new welcome kits.
            If you confirm, it will receive welcome kits but will remain marked as “paused”.`}
          icon={<InfoCircleOutlined size={16} />}
          showIcon
        />
      )}

      <div className="input-container">
        <label>How many do you want to send?</label>
        <Input
          placeholder="Enter a quantity"
          type="number"
          min={1}
          value={currentQuantity}
          onChange={(event) => {
            setCurrentQuantity(parseInt(event.target.value, 10));
          }}
        />
      </div>
      {selectedHotel?.maxQuantity &&
        currentQuantity &&
        currentQuantity + selectedHotel.currentStock >
          selectedHotel?.maxQuantity && (
          <Alert
            className="hotel-alert"
            type="info"
            message="Limited space"
            description={`This hotel cannot store more than ${
              selectedHotel?.maxQuantity
            } welcome kit${selectedHotel?.maxQuantity === 1 ? '' : 's'}.`}
            icon={<InfoCircleOutlined size={16} />}
            showIcon
          />
        )}
      {renderStockEstimate()}
    </Modal>
  );
};
