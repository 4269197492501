import { FilterFilled } from '@ant-design/icons';
import { Checkbox, Col, Popover, Row } from 'antd';
import { useState } from 'react';

const ModalCheckBox = ({
  defaultValue,
  value,
  onChange,
  title,
  items,
  isCenter = true,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const checkboxItem = (cbi: any) => (
    <Row key={cbi.label}>
      <Col>
        <Checkbox value={cbi.value}>{cbi.label}</Checkbox>
      </Col>
    </Row>
  );

  const checkbox = (
    <Checkbox.Group
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
    >
      {items.map(checkboxItem)}
    </Checkbox.Group>
  );

  return (
    <Popover
      content={checkbox}
      trigger="click"
      open={isVisible}
      onOpenChange={setIsVisible}
    >
      <div
        className="sort-button min-width-90"
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: isCenter ? 'center' : 'space-between',
        }}
      >
        {title}
        <FilterFilled style={{ color: '#BFBFBF', marginLeft: '10px' }} />
      </div>
    </Popover>
  );
};

type Value = any;

type Props = {
  defaultValue?: Array<Value>;
  value?: Array<Value>;
  onChange: (checkedValue: Array<Value>) => void;
  title: string;
  items: Array<{
    value: Value;
    label: string;
  }>;
  isCenter?: boolean;
};

export default ModalCheckBox;
