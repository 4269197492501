import { Fragment } from 'react';

import { WeekDays } from 'app/components/commons/Experience/Availability/utils';
import { Bed, BedCategory, RoomCategory } from 'app/typings';
import { ExtranetExperience } from 'app/typings/packages';
import { hasBabyBed, maxRoomCapacity } from 'app/utils/room';
import { pluralize } from 'app/utils/strings';

export const formattedPriceValues = (
  price: number,
  priceDescription: string | null,
  currencyFormatter: (value: number) => string
) => {
  const priceValue = currencyFormatter(price || 0);

  return `${priceValue} ${priceDescription ?? ''}`;
};

export const formattedExpAddOnRules = (experience: ExtranetExperience) => {
  const hasMaxQuantity = !!experience.maxQuantity;
  const hasLastMinuteRule = !!experience.closedHoursBeforeCheckin;
  const hasOnlyOnSpecificDates =
    experience.availableDates && experience.availableDates.length > 0;
  const hasOnlyOnSpecificDays =
    experience.availableDays &&
    experience.availableDays.length > 0 &&
    experience.availableDays.length < Object.values(WeekDays).length;

  const validRules = [
    hasMaxQuantity && 'Maximum quantity rule',
    hasLastMinuteRule && 'Last minute rule',
    hasOnlyOnSpecificDays && 'Only on specific days',
    hasOnlyOnSpecificDates && 'Only on specific dates',
  ].filter(Boolean) as string[];

  const validRulesCount = validRules.length;

  if (validRulesCount === 1) {
    return validRules[0];
  }

  if (validRulesCount > 0) {
    return pluralize(
      validRulesCount,
      'availability rule',
      'availability rules'
    );
  }

  return undefined;
};

export const formattedRoomValue = (
  beds: Bed[],
  bedCategories: BedCategory[]
) => {
  const roomCapacity = pluralize(
    maxRoomCapacity(beds, bedCategories),
    'guest',
    'guests'
  );

  const babyBedInfo = hasBabyBed(beds) ? (
    <>
      <br />1 baby
    </>
  ) : null;

  return (
    <>
      {roomCapacity}
      {babyBedInfo}
    </>
  );
};

export const formattedBeds = (beds: Bed[], bedCategories: BedCategory[]) => {
  const bedNames = beds
    .map((bed) =>
      bedCategories?.find((category) => category?.id === bed.categoryId)
    )
    .filter(Boolean)
    .sort((a, b) => (a?.priority ?? 0) - (b?.priority ?? 0))
    .map((category) => category?.name);

  return (
    <>
      {bedNames.map((name, index) => (
        <Fragment key={index}>
          {name}
          <br />
        </Fragment>
      ))}
    </>
  );
};

export const formatRoomTitle = (
  title: string | null,
  categoryId: number,
  categories: RoomCategory[]
) => {
  if (title) {
    return title;
  }

  return categories?.find((exp) => exp?.id === categoryId)?.name ?? '';
};
