import { Tabs, TabsProps } from 'antd';

import PackageButton from '../PackageButton/PackageButton';

import './PackageTabs.scss';

type PackagesTabsProps = {
  onChangeTabs: (key: string) => void;
  items: TabsProps['items'];
  activeSlug?: string;
};

const PackagesTabs = ({
  onChangeTabs,
  items,
  activeSlug,
}: PackagesTabsProps) => {
  return (
    <div className="package-tabs-container">
      <Tabs
        defaultActiveKey="1"
        onChange={onChangeTabs}
        items={items}
        tabBarExtraContent={<PackageButton packageSlug={activeSlug ?? ''} />}
      />
    </div>
  );
};

export default PackagesTabs;
