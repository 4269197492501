import React from 'react';

export const StaycationLogoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 72 72"
    width="24"
    height="24"
  >
    <rect width="72" height="72" fill="#000" rx="12" />
    <path
      fill="#fff"
      d="M40.305 59.16c-1.897 0-3.462-1.598-3.462-3.529 0-1.93 1.565-3.528 3.462-3.528 1.897 0 3.428 1.565 3.428 3.528 0 1.964-1.531 3.528-3.428 3.528Zm-24.862 5.025c5.193 0 7.623-2.23 7.656-5.625.033-2.696-1.531-3.961-3.794-4.493l-4.827-1.132c-.8-.2-1.031-.432-1.031-.931 0-.633.665-1.032 1.897-1.032 1.23 0 1.996.566 2.03 1.365h6.69v6.856c0 3.028 1.73 4.76 4.893 4.76h2.33v-5.026h-.566c-.799 0-1.265-.5-1.265-1.365v-5.225h1.83V47.31h-1.83V43.15l-5.392 1.098v3.063h-1.73v2.596c0 .2-.234.232-.334.066-1.132-1.797-3.494-2.928-6.59-2.928-4.56 0-7.29 2.33-7.29 5.458 0 2.962 2.197 4.493 5.325 5.093l2.896.565c.732.134 1.232.433 1.232.966 0 .665-.732 1.165-1.997 1.165-1.465 0-2.263-.566-2.496-1.698H7.622c.3 3.329 2.962 5.592 7.821 5.592m36.411 2.928h5.892l7.489-19.804h-5.892l-2.563 7.356c-.1.266-.366.266-.465 0l-2.563-7.355H43.667v.931c0 .3-.166.366-.4.134-.899-.866-2.296-1.331-3.861-1.331-4.427 0-7.955 3.727-7.955 8.586 0 4.86 3.495 8.587 7.821 8.587 1.498 0 3.029-.566 3.994-1.43.233-.2.4-.1.4.165v1h5.192V51.437c0-.3.4-.366.5-.067l4.127 10.918c.066.133.1.332.034.465l-1.665 4.361Z"
    />
  </svg>
);
