import { Button } from 'antd';

import RedirectIcon from 'app/components/pages/Hotels/RedirectIcon';
import { config } from 'app/redux/store';

import './PackageButton.scss';

type PackageButtonProps = {
  packageSlug: string;
};

const PackageButton = ({ packageSlug }: PackageButtonProps) => {
  return (
    <Button
      href={`${config.appUrl}/fr/hotels/${packageSlug}`}
      target="_blank"
      className="package-button"
    >
      Preview page <RedirectIcon />
    </Button>
  );
};

export default PackageButton;
