import classNames from 'classnames';
import { useState } from 'react';

import RedirectIcon from 'app/components/pages/Hotels/RedirectIcon';

import Pic from '../Pic';

type TableRowProps = {
  title: string;
  coverPicturePath: string;
  imageType: 'image' | 'emoji';
  description?: string;
  primaryInfo?: React.ReactNode;
  secondaryInfo?: React.ReactNode;
  haveTags?: boolean;
  onClick?: () => void;
};

const TableRow = ({
  title,
  coverPicturePath,
  imageType,
  description,
  primaryInfo,
  secondaryInfo,
  haveTags = false,
  onClick,
}: TableRowProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div className="table-row">
      <div className="left-info">
        <div className="visual">
          <Pic
            pictureId={coverPicturePath}
            className={imageType}
            alt={coverPicturePath}
          />
        </div>
        <div className="titles">
          <div className="title">{title}</div>
          {description && (
            <div className="description">
              <span>{description}</span>
            </div>
          )}
        </div>
      </div>
      <div className="right-info">
        <div className="primary-info">
          <div
            className={classNames([
              'info',
              { isClickable: !!onClick && primaryInfo && isHover },
            ])}
            onClick={onClick}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <span>{primaryInfo}</span>
            {!!onClick && primaryInfo && isHover && <RedirectIcon />}
          </div>
        </div>

        <div className="secondary-info">
          <span>{secondaryInfo}</span>
        </div>

        {haveTags && <div className="tag-info"></div>}
      </div>
    </div>
  );
};

export default TableRow;
