const BabyBedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.3333 18.3332C17.875 18.3332 17.5 17.9582 17.5 17.4998V16.6665H2.5V17.4998C2.5 17.9582 2.125 18.3332 1.66667 18.3332C1.20833 18.3332 0.833333 17.9582 0.833333 17.4998V4.99984C0.375 4.99984 0 4.62484 0 4.1665C0 3.70817 0.375 3.33317 0.833333 3.33317V2.49984C0.833333 2.0415 1.20833 1.6665 1.66667 1.6665C2.125 1.6665 2.5 2.0415 2.5 2.49984V3.33317H17.5V2.49984C17.5 2.0415 17.875 1.6665 18.3333 1.6665C18.7917 1.6665 19.1667 2.0415 19.1667 2.49984V3.33317C19.625 3.33317 20 3.70817 20 4.1665C20 4.62484 19.625 4.99984 19.1667 4.99984V17.4998C19.1667 17.9582 18.7917 18.3332 18.3333 18.3332ZM2.5 14.9998H17.5V13.3332H2.5V14.9998ZM15.8333 11.6665H17.5V4.99984H15.8333V11.6665ZM12.5 11.6665H14.1667V4.99984H12.5V11.6665ZM9.16667 11.6665H10.8333V4.99984H9.16667V11.6665ZM5.83333 11.6665H7.5V4.99984H5.83333V11.6665ZM2.5 11.6665H4.16667V4.99984H2.5V11.6665Z"
      fill="#808080"
    />
  </svg>
);

export default BabyBedIcon;
