import { Button, Form, Input, Layout, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { HOTEL_POSITION_ENUM } from 'app/components/commons/BookingContact/Utils';
import { StaycationLogo } from 'app/components/commons/icons/StaycationLogo';
import { useInitHotelUserPassword } from 'app/hooks/data/useInitHotelUserPassword';

import './InitPassword.scss';

const { Header, Content } = Layout;

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const InitPassword = () => {
  const queryParams = useQueryParams();
  const { mutate } = useInitHotelUserPassword();

  const email = queryParams.get('email');
  const role = queryParams.get('role');
  const token = queryParams.get('token');
  const redirect = queryParams.get('next');

  const onSignUp = (values: Store) => {
    const { email, password, role } = values;

    if (redirect && token) {
      mutate({ email, role, password, redirect, token });
    }
  };

  const errorMessage = (msg: any) => (
    <div className="formItemContainer__error">{msg}</div>
  );

  return (
    <Layout className="app">
      <Header className="init-pwd-header">
        <StaycationLogo />
      </Header>
      <Content className="init-pwd-content">
        <Form
          initialValues={{ email, role }}
          className="form-card"
          onFinish={onSignUp}
        >
          <div className="formHeader">
            <div className="form-card__title">Create your account</div>
            <div className="form-card__subtitle">
              Enter the personal information you will need to access your
              extranet.
            </div>
          </div>
          <div className="formBody">
            <div className="formItemContainer">
              <div className="formItemContainer__title">Email address</div>
              <Form.Item name="email">
                <Input disabled className="formItemContainer__input" />
              </Form.Item>
            </div>
            <div className="formItemContainer">
              <div className="formItemContainer__title">
                What position describes you best ?
              </div>
              <Form.Item name="role">
                <Select
                  className="formItemContainer__input"
                  placeholder="Select position"
                  options={[
                    ...HOTEL_POSITION_ENUM.map((position) => ({
                      label: position,
                      value: position.toLowerCase(),
                    })),
                  ]}
                />
              </Form.Item>
            </div>
            <div className="formItemContainer">
              <div className="formItemContainer__title">Password</div>
              <Form.Item
                name="password"
                className="formItemContainer__input"
                rules={[
                  {
                    required: true,
                    message: errorMessage('Password is required'),
                  },
                  {
                    min: 8,
                    message: errorMessage('Must be at least 8 characters'),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit">
              Sign up
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export default InitPassword;
