import { Button, Modal } from 'antd';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const ConfirmOrderModal = ({ isOpen, onClose, onSubmit }: Props) => {
  return (
    <Modal
      title="Confirm order"
      className="confirm-order-modal"
      open={isOpen}
      onCancel={onClose}
      centered
      footer={[
        <Button type="ghost" className="close-button" onClick={onClose}>
          Close
        </Button>,
        <Button
          key="save"
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
          className="saveBtn"
        >
          Confirm order
        </Button>,
      ]}
      destroyOnClose
    >
      <div>
        By confirming order, you understand that you are initiating the delivery
        process for the welcome kits.
      </div>
    </Modal>
  );
};

export default ConfirmOrderModal;
